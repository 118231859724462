<div class="setup-page-container text-center">
  <div class="logo-block d-flex justify-content-center">
    <img
      class="logo"
      [src]="'/assets/images/' + domainConfigData.logo"
      [alt]="domainConfigData.alt"
    />
  </div>

  <router-outlet></router-outlet>

  <div class="information">
    <div class="fs-sm-txt">
      <span
        class="fs-txt-white-grey-color-hover"
      >{{ 'login.intro' | translate }}
        <br />
        {{ 'login.labelcontact' | translate }}:
      </span>
      <a
        class="fs-txt-white-grey-color-hover"
        href="mailto:info@filmsolutions.com?Subject=Contact%20Support"
        target="_top"
      >info&#64;filmsolutions.com</a
      >
    </div>
    <div class="fs-sm-txt">
      <a
        class="fs-txt-white-grey-color-hover" href="tel:+1818-588-3500"
      >+1 (818) 588 3500</a
      >
    </div>
    <div
      class="language-select-container my-2 d-flex justify-content-center"
      *ngIf="selectedLanguage"
    >
      <button
        mat-button
        class="language-menu-button px-3 py-0"
        [matMenuTriggerFor]="languageMenu"
        (menuOpened)="languageMenuOpened = true"
        (menuClosed)="languageMenuOpened = false"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start align-items-center">
            <img
              class="mr-2"
              src="assets/images/icons/{{ selectedLanguage.value.icon }}"
            />
            <span class="fs-language-label">{{ selectedLanguage.label }}</span>
          </div>
          <div>
            <mat-icon
              class="d-block" *ngIf="!languageMenuOpened"
            >keyboard_arrow_down
            </mat-icon
            >
            <mat-icon
              class="d-block" *ngIf="languageMenuOpened"
            >keyboard_arrow_up
            </mat-icon
            >
          </div>
        </div>
        <mat-menu [classList]="'fs-language-menu'" #languageMenu="matMenu">
          <button
            class="mb-1 language-menu-container"
            *ngFor="let language of availableLanguages"
            (click)="changeLanguage(language.value)"
          >
            <div
              class="language-button d-flex justify-content-start align-items-center"
            >
              <img
                class="mr-2"
                src="assets/images/icons/{{ language.value.icon }}"
              />
              <span class="fs-txt-white-grey-color-hover fs-tiny-txt">{{
                  language.label
                }}</span>
            </div>
          </button>
        </mat-menu>
      </button>
    </div>
  </div>
</div>
