import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { SetupComponent } from './setup.component';
import { MfaSetupComponent } from './mfa-setup/mfa-setup.component';
import { MfaSetupSmsComponent } from './mfa-setup-sms/mfa-setup-sms.component';
import { MfaSetupSmsVerifyComponent } from './mfa-setup-sms-verify/mfa-setup-sms-verify.component';
import { MfaSetupTotpComponent } from './mfa-setup-totp/mfa-setup-totp.component';
import { MfaFinishComponent } from './mfa-finish/mfa-finish.component';
import { ProfileComponent } from './profile/profile.component';
import { UserService } from '../shared/services/user.service';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: SetupComponent,
        children: [
          {
            path: 'mfa',
            component: MfaSetupComponent,
          },
          {
            path: 'mfa/sms',
            component: MfaSetupSmsComponent,
          },
          {
            path: 'mfa/sms-verify',
            component: MfaSetupSmsVerifyComponent,
          },
          {
            path: 'mfa/totp',
            component: MfaSetupTotpComponent,
          },
          {
            path: 'mfa/finish',
            component: MfaFinishComponent,
          },
          {
            path: 'profile',
            component: ProfileComponent,
            resolve: {
              contact: () => {
                return inject(UserService).getContactProfile();
              },
            },
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class SetupRouting {}
