import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LauncherService } from '../../../launcher/launcher.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigKey } from '../../../../appSettings/key-config';
import { SliderService } from './slider/slider.service';
import { AlbumColorTagsService } from '../../../../shared/services/album-color-tags.service';
import { TopmenusService } from '../../dashboard-shared/top-menu/top-menu.service';
import { AssetInfo } from '../../../../shared/models/assetInfo.model';
import { ProjectDetailPermission } from '../../../../shared/models/projectpermission.model';
import { UserDetails } from '../../../../shared/models/userdetails.model';
import { ViewProjectService } from '../../../../shared/services/view-project.service';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { Modalstatusservice } from '../../../../shared/services/modalstatus.service';
import { PaginationService } from '../../dashboard-shared/pagination/pagination.service';
import { UserService } from '../../../../shared/services/user.service';
import { AuthServerProvider } from '../../../../shared/services/auth.jwt.service';
import { TaggerService } from '../../../../shared/services/tagger.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { ConstantsGlobal } from '../../../../shared/constants/constants';
import { UserRole } from '../../../../shared/enum/user-role.enum';
import { Utils } from '../../../../shared/utils/utils';
import { DialogService } from '../../../../shared/services/dialog/dialog.service';
import { ConflictsComponent } from '../../dashboard-shared/left-menu/menus/audit/conflicts/conflicts.component';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { Taggingview2Component } from '../../dashboard-shared/left-menu/menus/administration/taggingview-2/taggingview-2.component';
import { Taggingview1Component } from '../../dashboard-shared/left-menu/menus/administration/taggingview-1/taggingview-1.component';
import { TopMenuComponent } from '../../dashboard-shared/top-menu/top-menu.component';
import { map, takeUntil } from 'rxjs/operators';
import { SelectedImagesStore } from '../../../../shared/store/selected-images-store';
import { LastSelectedImageStore } from '../../../../shared/store/last-selected-image-store';
import { UnsubscriberComponent } from '../../../../shared/components/unsubscriber/unsubscriber.component';
import { DomainConfigService } from '../../../../shared/services/domain-config.service';
import { DomainConfig } from '../../../../shared/models/domainConfig.model';
import { ProjectRole } from '../../../../shared/enum/project-role.enum';
import { DownloadService } from '../../dashboard-shared/top-menu/download/download.service';
import { concatMap, Subject } from 'rxjs';
import {ApiErrorHandlerService} from '../../../../shared/services/api-error-handler.service';

@Component({
  selector: 'fs-enlarge',
  templateUrl: './enlarge.component.html',
  styleUrls: ['./enlarge.component.scss'],
})
export class EnlargeComponent
  extends UnsubscriberComponent
  implements OnInit, OnDestroy
{
  ShowHideRejected = true;
  projectId: string;
  projectImageData: AssetInfo = new AssetInfo();
  enlargeImageData = [];
  albumList: any;
  albumData: any;
  viewSetting: ProjectDetailPermission = new ProjectDetailPermission();

  noOfImages = 1;
  pageNo: number;
  imageId: string;
  totalImages: number;
  totalPages: number;
  currentImageNum: number;
  imageObjIndex = 0;
  currentPageNumber: number;
  isSuperZoom = false;
  albumListObser: any;
  modalStatusObser: any;
  isLeftMenuPartShown = true;
  imageLoaded: boolean[] = [];
  isLeftAlbumActive: boolean;
  selectedLeftAlbum: any;
  timer: any;
  activatedLeftProjectAlbum = false;
  projectDetailPermission: ProjectDetailPermission =
    new ProjectDetailPermission();
  topMenuComp: any;
  hasDownloadAccess = false;
  isEnlargeView = false;
  currentUser: UserDetails = new UserDetails();
  projectAuthority: string;
  userAuthority: string;
  mouseHide: boolean;
  selectedProjectValue: any;
  isExecutiveAlbumView = false;
  secureStorage = true;
  token: any;
  domainConfig: DomainConfig;
  userRole = UserRole;
  projectRole = ProjectRole;
  currentFilter: string;
  taggingView1Open: boolean;
  taggingView2Open: boolean;
  isTaggingView1Active = false;
  isTaggingView2Active = false;
  isConflictsActive: boolean;
  isTaggingInProgress = false;

  isCloseEnlarge = false;
  projectImageIds: any;

  ignorFirstPaginationEvent = true;
  mouseExcluded = true;
  isTaggerRole: boolean;

  constantsGlobalData: any;
  selectedLeftAlbumKeys: any;
  selectedLeftProjectAlbumKeys: any;
  talentUseSetup = false;
  private tagRequestSubject: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private modalService: Modalstatusservice,
    private topmenusService: TopmenusService,
    private paginationService: PaginationService,
    private elementRef: ElementRef,
    private userService: UserService,
    private launcherService: LauncherService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private downloadService: DownloadService,
    private domainConfigService: DomainConfigService,
    private translate: TranslateService,
    private auth: AuthServerProvider,
    private taggerService: TaggerService,
    private sliderService: SliderService,
    private loaderService: LoaderService,
    private albumColorTagsService: AlbumColorTagsService,
    private dialogService: DialogService,
    private appStateService: AppStateService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private ref: ChangeDetectorRef,
  ) {
    super();
    this.token = this.auth.getToken();
    this.updateAssetTagging();
  }

  ngOnInit() {
    this.constantsGlobalData = ConstantsGlobal;
    this.isTaggerRole = this.userService.isAdminWithTaggerRole();

    this.viewSetting =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.viewSetting,
      );
    this.currentUser = this.userService.mergeWithCurrentUser(this.currentUser);

    this.userService.userAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthority) => {
        this.userAuthority = userAuthority;
      });

    this.viewProjectService.currentProjectAuthority$
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectAuthority) => {
        this.projectAuthority = projectAuthority;
      });

    this.topmenusService.setTopMenuStatus('Enlarge');
    this.paginationService.setPaginationTempalte('EnlargeImage');
    this.topmenusService.setTopMenuPagination(true);

    this.viewProjectService.projectImageIdsGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageIds) => {
        this.projectImageIds = imageIds;
      });
    this.paginationService.paginationIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (!this.ignorFirstPaginationEvent) {
          if (index === -1) {
            this.lastPage();
          } else if (index > 0 && !this.isCloseEnlarge) {
            this.paginationEvt(index);
          }
        } else {
          this.ignorFirstPaginationEvent = false;
        }
      });
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((parameters) => {
      this.imageId = parameters['imageId'];
      this.projectId = parameters.projectId.slice(
        1,
        parameters.projectId.length,
      );
      this.currentFilter = parameters.filterId;
      this.imageSetup(this.imageId);
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isEnlargeView =
          event.url.search('enlarge') !== -1 ||
          event.url.search('compare') !== -1;
      }
    });

    this.viewProjectService.activatedLeftTabIndexGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((index) => {
        if (index === 0) {
          this.isLeftAlbumActive = true;
        } else {
          this.isLeftAlbumActive = false;
        }
      });

    this.handleModalStatus();

    this.viewProjectService.isTaggingView1OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView1Open = status;
      });

    this.viewProjectService.isTaggingView2OpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.taggingView2Open = status;
      });

    this.viewProjectService.isLeftMenuOpenGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((leftmenuState) => {
        this.isLeftMenuPartShown = leftmenuState;
      });

    this.sidebarService.selectedAlbum
      .pipe(takeUntil(this.destroy$))
      .subscribe((album) => {
        this.selectedLeftAlbum = album;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectValue) => {
        this.selectedProjectValue = Number(projectValue);
      });

    this.viewProjectService.isExecutiveAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isExecutiveAlbumView = status;
      });
    this.viewProjectService.projectDetailPermissionGLobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.viewSetting = data;
        this.projectDetailPermission = data;
        if (!Utils.isObjectEmpty(data)) {
          if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
            this.talentUseSetup = data.useSetup;
          }
          if (data.permissionDTO) {
            this.hasDownloadAccess = !!data.permissionDTO.downloadType;
            if (data.permissionDTO.secureAccess) {
              this.secureStorage = data.permissionDTO.secureAccess;
            }
          }
        }
      });

    this.viewProjectService.tagginView1DataEnlarge
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isTaggingView1Active = status;
      });
    this.viewProjectService.tagginView2DataEnlarge
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isTaggingView2Active = status;
      });

    this.viewProjectService.ConflictsEnlarge.pipe(
      takeUntil(this.destroy$),
    ).subscribe((status) => {
      this.isConflictsActive = status;
    });

    this.sidebarService.selectedAlbumKeys
      .pipe(takeUntil(this.destroy$))
      .subscribe((albums) => {
        this.selectedLeftAlbumKeys = albums;
      });

    this.sidebarService.projectAlbumKeysGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((albums) => {
        this.selectedLeftProjectAlbumKeys = albums;
      });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainConfig) => {
        if (domainConfig) {
          this.domainConfig = domainConfig;
        }
      });

    this.viewProjectService.activatedLeftProjectAlbumGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.activatedLeftProjectAlbum = status;
      });

    this.viewProjectService.setIsRedirectedFromEnlarge(true);

    this.sidebarService.albumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumData = albumList;
          this.albumList = Utils.getValuesByKey(albumList.albums, 'id');
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.enlargeImageData,
            this.albumList,
          );
        }
      });

    // TODO what the hell??? Why do we have albumList and updatedAlbumList ???

    this.sidebarService.updatedAlbumList
      .pipe(takeUntil(this.destroy$))
      .subscribe((albumList) => {
        if (!Utils.isObjectEmpty(albumList)) {
          this.albumList = Utils.getValuesByKey(albumList, 'id');
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.enlargeImageData,
            this.albumList,
          );
        }
      });

    this.albumColorTagsService.isColorPanelEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status) {
          this.albumColorTagsService.updateImageDataWithColorCodes(
            this.enlargeImageData,
            this.albumList,
          );
        }
      });
  }

  showRejected(x) {
    if (x === true) {
      this.ShowHideRejected = true;
      this.mouseExcluded = true;
    }
  }

  hideRejected(y) {
    if (y === true) {
      this.ShowHideRejected = false;
      this.mouseExcluded = false;
    }
  }

  imageSetup(imageId) {
    this.viewSetting =
      this.viewProjectService.mergeWithProjectDetailPermissionData(
        this.viewSetting,
      );
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );

    this.totalImages = this.projectImageIds.length;
    // Jaydeep
    this.totalPages = Math.ceil(
      this.projectImageData.assetInfoDTOList.length /
        this.viewSetting.viewSettingDTO.imagePerPage,
    );

    this.paginationService.setPaginationLastIndexGlobal(this.totalImages);
    this.enlargeImageData = [];
    for (let i = 0; i < this.projectImageData.assetInfoDTOList.length; i++) {
      if (Number(imageId) === this.projectImageData.assetInfoDTOList[i].id) {
        this.imageObjIndex = i;
        this.currentImageNum =
          this.projectImageIds.indexOf(Number(imageId)) + 1;
        this.enlargeImageData.push(this.projectImageData.assetInfoDTOList[i]);
        this.mouseHide = this.projectImageData.assetInfoDTOList[i].rejected;
      }
    }
    this.paginationEvt(this.currentImageNum);
  }

  paginationEvt(index) {
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    this.imageLoaded = [];
    if (index) {
      this.currentImageNum = index;
    }
    this.currentPageNumber = Math.ceil(
      this.currentImageNum / this.viewSetting.viewSettingDTO.imagePerPage,
    );
    const isAvailable = this.projectImageData.assetInfoDTOList
      .map(function (e) {
        return e.id;
      })
      .indexOf(this.projectImageIds[index - 1]);

    if (isAvailable !== -1) {
      // Jaydeep
      this.handleEnlargeRoute();
      this.handleSelectedImage();
      this.handleLastSelectedImages();
      this.loadLastSelectedImage(
        this.projectImageIds[this.currentImageNum - 1],
      );
    } else {
      this.enlargeImageData = [];
      this.loadProjectDataWithAssetIds();
    }
  }

  loadProjectDataWithAssetIds() {
    const assetsIds = Utils.getImageIdsBasedOnPage(
      this.currentPageNumber,
      this.viewSetting.viewSettingDTO.imagePerPage,
      this.projectImageIds,
    );
    const data = {
      assetIds: assetsIds,
      projectId: this.projectId,
      filter: this.currentFilter,
    };
    this.viewProjectService
      .callAssetsInfoBasedOnAssetsId(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.projectImageData = res;
        this.viewProjectService.setProjectData(this.projectImageData);
        this.handleEnlargeRoute();
        this.handleLastSelectedImages();
        this.handleSelectedImage();
        this.loadLastSelectedImage(
          this.projectImageIds[this.currentImageNum - 1],
        );
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  selectImageOnDoubleClick() {
    this.superZoom();
  }

  selectZoomImageOnDoubleClick() {
    this.closeZoom();
  }
  onLoad(i) {
    this.imageLoaded[i] = true;
    this.albumColorTagsService.updateImageDataWithColorCodes(
      this.enlargeImageData,
      this.albumList,
    );
  }

  closeZoom() {
    this.imageLoaded = [];
    if (this.isSuperZoom) {
      this.isSuperZoom = !this.isSuperZoom;
      this.topmenusService.setTopMenuStatus('Enlarge');
    } else {
      this.viewProjectService.setCurrentPageNumber(this.currentPageNumber);
      this.isCloseEnlarge = true;
      this.sidebarService.resetAlbumImageData();
      this.paginationService.setPaginationIndex(this.currentPageNumber);
      this.modalService.resetActiveModal();
      this.topmenusService.setTopMenuStatus('');
      this.router.navigate([
        Utils.prepareFilterNavigationURL(
          this.viewProjectService.getProjectRole(),
          this.viewProjectService.getProjectId(),
          this.currentFilter,
          this.currentPageNumber,
        ),
      ]);

      setTimeout(() => {
        if (this.isTaggingView1Active) {
          this.viewProjectService.setTagginView1DataEnlarge(true);
        }

        if (this.isTaggingView2Active) {
          this.viewProjectService.setTagginView2DataEnlarge(true);
        }

        if (this.isConflictsActive) {
          this.viewProjectService.setConflictsEnlarge(true);
        }
      }, 200);
    }
  }

  superZoom() {
    this.isSuperZoom = true;
    this.imageLoaded = [];
    this.topmenusService.setTopMenuStatus('SuperZoom');
  }

  lastPage(): void {
    this.imageLoaded = [];
    this.currentPageNumber = this.totalPages;
    this.currentImageNum = this.totalImages;
    this.handleEnlargeRoute();
    this.handleLastSelectedImages();
    this.handleSelectedImage();
  }

  handleLastSelectedImages() {
    if (
      this.projectImageData &&
      this.projectImageData.assetInfoDTOList &&
      this.projectImageData.assetInfoDTOList.length > 0
    ) {
      this.lastSelectedImageStore.set(
        this.projectImageData.assetInfoDTOList[this.imageObjIndex],
      );
    }
  }

  handleSelectedImage() {
    if (this.isSelectedImageExist()) {
      this.selectedImagesStore.clear();
      this.selectedImagesStore.add(
        this.projectImageData.assetInfoDTOList[this.imageObjIndex],
      );
    }
  }

  isSelectedImageExist() {
    return (
      this.projectImageData &&
      this.projectImageData.assetInfoDTOList &&
      this.projectImageData.assetInfoDTOList.length
    );
  }

  loadLastSelectedImage(imageID) {
    const restartData = {
      filter: this.currentFilter,
      assetId: imageID,
      projectId: this.viewProjectService.getProjectId(),
      pageNo: this.currentPageNumber,
    };
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.viewProjectService
        .callLastSelectedImage(restartData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {},
          (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        });
    }, 3000);
  }

  handleModalStatus() {
    this.appStateService.zoomDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: { loaded: boolean }) => {
        if (state.loaded) {
          this.superZoom();
        }
      });

    this.appStateService.closeZoomDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: { loaded: boolean }) => {
        if (state.loaded) {
          this.closeZoom();
        }
      });

    this.appStateService.approveDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }: { loaded: boolean }) => {
        if (loaded) {
          this.approveBorder();
        }
      });

    this.appStateService.rejectDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loaded }: { loaded: boolean }) => {
        if (loaded) {
          this.rejectBorder();
        }
      });

    this.appStateService.leftMenuAlbums$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        if (state.loaded) {
          this.isLeftAlbumActive = true;
        }
      });

    const conflictsState = this.dialogService.getDialogState(
      ConflictsComponent.identifier,
    );

    const taggingView1State = this.dialogService.getDialogState(
      Taggingview1Component.identifier,
    );
    const taggingView2State = this.dialogService.getDialogState(
      Taggingview2Component.identifier,
    );
    const enlargeImageState = this.dialogService.getDialogState(
      TopMenuComponent.identifier,
    );

    conflictsState.state$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.isConflictsActive = state.opened;
      if (this.isConflictsActive) {
        this.imageLoaded = [];
        this.selectedImagesStore.clear();
      }
    });

    taggingView1State.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        if (state.opened) {
          this.superZoom();
        }
      });

    enlargeImageState.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.isTaggingView1Active = state.opened;
        this.isTaggingView2Active = false;
      });

    taggingView2State.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.isTaggingView2Active = state.opened;
        this.isTaggingView1Active = false;
      });
  }

  approveBorder() {
    const obj = this.prepareExecutiveAlbumTagObj(
      1,
      this.projectImageIds[this.currentImageNum - 1],
    );
    this.callExicutiveAlbumTag(obj);
  }

  rejectBorder() {
    const obj = this.prepareExecutiveAlbumTagObj(
      3,
      this.projectImageIds[this.currentImageNum - 1],
    );
    this.callExicutiveAlbumTag(obj);
  }

  callExicutiveAlbumTag(obj) {
    this.viewProjectService
      .postExecutiveAlbumTag(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const imageIndex = this.projectImageData.assetInfoDTOList
          .map(function (e) {
            return e.id;
          })
          .indexOf(obj.assetId);

        if (this.selectedProjectValue === 3) {
          this.projectImageData.assetInfoDTOList[
            imageIndex
          ].executiveSignoffDTOList = res;
        } else {
          this.projectImageData.assetInfoDTOList[
            imageIndex
          ].executiveWideEditDTOList = res;
        }

        res.forEach((element) => {
          if (element.userId === this.currentUser.id) {
            if (element.actionTypeId === 3) {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].exicutiveRejected = 3;
              this.projectImageData.assetInfoDTOList[imageIndex].exicutiveLike =
                false;
            } else {
              this.projectImageData.assetInfoDTOList[
                imageIndex
              ].exicutiveRejected = 1;
            }
          }
        });
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  prepareExecutiveAlbumTagObj(actionTypeId: number, assetId: number) {
    return {
      actionTypeId: actionTypeId,
      albumId: this.viewProjectService.getExecutiveAlbumID(),
      assetId: assetId,
      projectId: this.projectId,
      typeId: this.selectedProjectValue,
    };
  }

  callAlbumTagging(id) {
    const data = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      projectId: this.projectId,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.updateCustomeAlbumsID(id, res.t);
        this.sidebarService.setUpdatedTaggingAlbum(res);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callAlbumTaggingKeys(id) {
    const data = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      projectId: this.projectId,
      id: id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const obj = {
          status: res.status,
          message: res.message,
          id: id,
        };
        this.sidebarService.setUpdatedTaggingAlbum(obj);
        if (res.status) {
          this.updateCustomeAlbumsID(id, res.t);
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  callProjectAlbumTaggingKeys(albumObj) {
    const data = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      projectId: this.projectId,
      id: albumObj.id,
    };

    this.sidebarService
      .callAPIToTaggingAlbumData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const obj = {
          status: res.status,
          message: res.message,
          id: albumObj.id,
        };
        this.sidebarService.setUpdatedTaggingProjectAlbum(obj);
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  updateCustomeAlbumsID(id, isAdd) {
    isAdd = isAdd.tag;
    let index = -1;
    if (
      this.projectImageData &&
      this.projectImageData.assetInfoDTOList &&
      this.projectImageData.assetInfoDTOList.length > 0
    ) {
      index = this.projectImageData.assetInfoDTOList
        .map(function (e) {
          return e.id;
        })
        .indexOf(this.projectImageData.assetInfoDTOList[this.imageObjIndex].id);
    }
    if (index !== -1) {
      if (isAdd) {
        this.projectImageData.assetInfoDTOList[index].customAlbums.push(id);
      } else {
        const customeAlbumIndex: number =
          this.projectImageData.assetInfoDTOList[index].customAlbums.indexOf(
            id,
          );
        if (customeAlbumIndex !== -1) {
          this.projectImageData.assetInfoDTOList[index].customAlbums.splice(
            customeAlbumIndex,
            1,
          );
        }
      }
    }
  }

  onClickAdminExcludeRelease() {
    // Call web service
    this.loaderService.displayLoader(true);
    this.projectImageData = this.viewProjectService.mergeWithProjectData(
      this.projectImageData,
    );
    const obj = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      projectId: Number(this.projectId),
      currentAssetIds: [this.projectImageIds[this.currentImageNum - 1]],
    };
    this.viewProjectService
      .putSetExcudeRelease(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.status) {
            res.t.forEach((element) => {
              const index = this.projectImageData.assetInfoDTOList
                .map(function (e) {
                  return e.id;
                })
                .indexOf(element.assetId);
              this.projectImageData.assetInfoDTOList[index].excluded =
                element.excluded;
              this.projectImageData.assetInfoDTOList[index].releasedDate =
                element.releasedDate;
              if (this.selectedImagesStore.size() === 1) {
                this.selectedImagesStore.set(
                  this.projectImageData.assetInfoDTOList[index],
                );
              }
            });
          } else {
            this.alertCustom(res.message);
          }

          this.loaderService.displayLoader(false);
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.loaderService.displayLoader(false);
        },
      );
  }

  onRightClick() {
    return false;
  }

  callTaggingImages(index: number, tagView: number) {
    const data = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      tagId: this.viewSetting.projectTagListDTO[index].id,
      tagView: tagView,
      projectId: Number(this.projectId),
      index: index,
      currentImageNum: this.currentImageNum,
    };

    this.tagRequestSubject.next(data);
  }

  updateAssetTagging() {
    this.tagRequestSubject.pipe(
      concatMap(data => this.taggerService.postTaggingImages(data).pipe(
        takeUntil(this.destroy$),
        map(res => ({ response: res, request: data }))
      )),
    ).subscribe(({ response, request }) => {
      if (response.status) {
        this.updateTaggingData(request.index, response, request .currentImageNum);
        const obj = {
          count: response.message,
          index: request.index,
          hotKey: this.viewSetting.projectTagListDTO[request.index].hotkey,
        };
        if (request.tagView === 1) {
          this.viewProjectService.setTagginView1Data(obj);
        } else {
          this.viewProjectService.setTagginView2Data(obj);
        }
        this.ref.markForCheck();
      } else {
        this.alertCustom(response.message);
      }
    },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
      this.loaderService.displayLoader(false);
    });
  }

  updateTaggingData(indexTag, res, currentImageNum) {
    const imageIndex =
      currentImageNum -
      (this.currentPageNumber * this.viewSetting.viewSettingDTO.imagePerPage -
        this.viewSetting.viewSettingDTO.imagePerPage) -
      1;
    if (this.isTaggingView1Active) {
      if (
        this.projectImageData.assetInfoDTOList[imageIndex].taggingView1.indexOf(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        ) === -1
      ) {
        this.projectImageData.assetInfoDTOList[imageIndex].taggingView1.push(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        );
        this.projectImageData.assetInfoDTOList[imageIndex].taggingView1.sort(
          (a, b) => a.localeCompare(b),
        );
      } else {
        const index = this.projectImageData.assetInfoDTOList[
          imageIndex
        ].taggingView1.indexOf(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        );
        if (index > -1) {
          this.projectImageData.assetInfoDTOList[
            imageIndex
          ].taggingView1.splice(index, 1);
        }
      }
    } else if (this.isTaggingView2Active) {
      if (
        this.projectImageData.assetInfoDTOList[imageIndex].taggingView2.indexOf(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        ) === -1
      ) {
        this.projectImageData.assetInfoDTOList[imageIndex].taggingView2.push(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        );
        this.projectImageData.assetInfoDTOList[imageIndex].taggingView2.sort(
          (a, b) => a.localeCompare(b),
        );
      } else {
        const index = this.projectImageData.assetInfoDTOList[
          imageIndex
        ].taggingView2.indexOf(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        );
        if (index > -1) {
          this.projectImageData.assetInfoDTOList[
            imageIndex
          ].taggingView2.splice(index, 1);
        }
      }
    } else if (this.isConflictsActive) {
      if (
        this.projectImageData.assetInfoDTOList[imageIndex].auditTags.indexOf(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        ) === -1
      ) {
        this.projectImageData.assetInfoDTOList[imageIndex].auditTags.push(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        );
        this.projectImageData.assetInfoDTOList[imageIndex].auditTags.sort(
          (a, b) => a.localeCompare(b),
        );
      } else {
        const index = this.projectImageData.assetInfoDTOList[
          imageIndex
        ].auditTags.indexOf(
          this.viewSetting.projectTagListDTO[indexTag].hotkey,
        );
        if (index > -1) {
          this.projectImageData.assetInfoDTOList[imageIndex].auditTags.splice(
            index,
            1,
          );
        }
      }
    }
  }

  callConflictsTaggingImages(index) {
    const data = {
      assetIds: [this.projectImageIds[this.currentImageNum - 1]],
      tagId: this.viewSetting.projectTagListDTO[index].id,
      projectId: this.viewProjectService.getProjectId(),
    };

    this.taggerService
      .postAuditTaggingImages(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status) {
          this.updateTaggingData(index, res, this.currentImageNum);
          const obj = {
            count: res.message,
            index: index,
            hotKey: this.viewSetting.projectTagListDTO[index].hotkey,
          };
          this.viewProjectService.setConflictsTagginData(obj);
        } else {
          this.alertCustom(res.message);
          // Handale Alert Message
        }
      },(error) => {
        this.apiErrorHandlerService.getHandler().handle(error);
        this.loaderService.displayLoader(false);
      });
  }

  isTaggingViewOpen() {
    return (
      this.taggingView1Open || this.taggingView2Open || this.isConflictsActive
    );
  }

  removeFocusout() {
    window.focus();
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyEvents(event: KeyboardEvent) {
    if (
      !this.dialogService.isAtLeastOneOpened() ||
      this.dialogService.isHandlingUpKeyEnabled()
    ) {
      // TaggingView-1
      if (this.isTaggingView1Active) {
        this.viewSetting.projectTagListDTO.forEach((element, index) => {
          if (
            event.key === element.hotkey.toLowerCase() ||
            event.key === element.hotkey.toUpperCase()
          ) {
            this.callTaggingImages(index, 1);
          }
        });
      }
      // TaggingView-2
      if (this.isTaggingView2Active) {
        this.viewSetting.projectTagListDTO.forEach((element, index) => {
          if (
            event.key === element.hotkey.toLowerCase() ||
            event.key === element.hotkey.toUpperCase()
          ) {
            this.callTaggingImages(index, 2);
          }
        });
      }

      // Conflicts
      if (this.isConflictsActive) {
        for (let i = 0; i < this.viewSetting.projectTagListDTO.length; i++) {
          if (
            event.key ===
              this.viewSetting.projectTagListDTO[i].hotkey.toLowerCase() ||
            event.key ===
              this.viewSetting.projectTagListDTO[i].hotkey.toUpperCase()
          ) {
            this.callConflictsTaggingImages(i);
          }
        }
      }
      if (event.keyCode === ConfigKey.ArrowRight) {
        this.sliderService.setKeyboardArrowState('right');
        if (this.currentImageNum === this.totalImages) {
          this.alertCustom(this.translate.instant('view-project.alert7'));
        } else {
          this.currentImageNum++;
          this.paginationEvt(this.currentImageNum);
        }
      } else if (event.keyCode === ConfigKey.ArrowLeft) {
        this.sliderService.setKeyboardArrowState('left');
        if (this.currentImageNum === 1) {
          this.alertCustom(this.translate.instant('view-project.alert7'));
        } else {
          this.currentImageNum--;
          this.paginationEvt(this.currentImageNum);
        }
      }

      if (!this.viewProjectService.isInputFocused()) {
        if (event.keyCode === ConfigKey.ArrowUp) {
          if (!this.isSuperZoom) {
            this.superZoom();
          }
        } else if (
          event.keyCode === ConfigKey.ArrowDown ||
          event.keyCode === ConfigKey.Esc
        ) {
          this.closeZoom();
        } else if (event.keyCode === ConfigKey.Space) {
          if (this.isSuperZoom) {
            this.closeZoom();
          } else {
            this.superZoom();
          }
        }
      }
    }

    if (
      !this.isTaggingViewOpen() &&
      (!this.dialogService.isAtLeastOneOpened() ||
        this.dialogService.isHandlingUpKeyEnabled())
    ) {
      if (!this.viewProjectService.isInputFocused()) {
        if (
          event.keyCode === ConfigKey.lowerZ ||
          event.keyCode === ConfigKey.upperZ
        ) {
          if (this.isSuperZoom) {
            this.closeZoom();
          } else {
            this.superZoom();
          }
        }

        if (
          event.keyCode === ConfigKey.lowerX ||
          event.keyCode === ConfigKey.upperX
        ) {
          if (
            !this.isTaggingView1Active &&
            !this.isTaggingView2Active &&
            !this.isConflictsActive
          ) {
            if (
              !this.isTaggerRole &&
              (this.userAuthority === UserRole.ROLE_ADMIN ||
                ((this.projectAuthority === ProjectRole.ROLE_EXEC ||
                  (this.projectAuthority === ProjectRole.ROLE_ALBUMVIEWER &&
                    !this.viewSetting.permissionDTO.execAccess)) &&
                  this.viewSetting.permissionDTO.releaseExcludeAccess))
            ) {
              this.onClickAdminExcludeRelease();
            } else if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
              if (this.selectedImagesStore.size() === 1) {
                if (
                  (this.selectedProjectValue !== 3 ||
                    this.selectedProjectValue !== 4) &&
                  this.selectedImagesStore.getHeadItem().status === 1
                ) {
                  this.onClickTalentApproveReject(
                    this.selectedImagesStore.getHeadItem(),
                  );
                }
              }
            }
          }
        }
        // Album Tagging
        if (
          event.keyCode === ConfigKey.lowerT ||
          event.keyCode === ConfigKey.upperT
        ) {
          if (!Utils.isObjectEmpty(this.selectedLeftAlbum)) {
            this.callAlbumTagging(this.selectedLeftAlbum.id);
          }
        }

        // Custom Album Tagging using Tag Keys
        if (
          this.isLeftAlbumActive &&
          !this.activatedLeftProjectAlbum &&
          !this.isTaggingView1Active &&
          !this.isTaggingView2Active
        ) {
          this.selectedLeftAlbumKeys.forEach((albumKeyObj) => {
            if (event.key === albumKeyObj.tagKey.toString()) {
              this.callAlbumTaggingKeys(albumKeyObj.id);
            }
          });
        }

        if (
          this.hasDownloadAccess &&
          this.projectAuthority !== ProjectRole.ROLE_TALENT &&
          !this.isTaggerRole
        ) {
          if (
            event.keyCode === ConfigKey.lowerD ||
            event.keyCode === ConfigKey.upperD
          ) {
            this.downloadService
              .downloadAssets(
                this.isEnlargeView,
                this.projectDetailPermission,
                this.userAuthority,
                this.projectAuthority,
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                () => {},
                (error) => {
                this.apiErrorHandlerService.getHandler().handle(error);
                this.loaderService.displayLoader(false);
              });
          }
        }

        // Project Album Tagging using Tag Keys
        if (
          this.isLeftAlbumActive &&
          this.activatedLeftProjectAlbum &&
          !this.isTaggingView1Active &&
          !this.isTaggingView2Active
        ) {
          this.selectedLeftProjectAlbumKeys.forEach((albumKeyObj) => {
            if (
              event.key.toLowerCase() ===
              albumKeyObj.tagKey.toString().toLowerCase()
            ) {
              this.callProjectAlbumTaggingKeys(albumKeyObj);
            }
          });
        }
      }
    }
  }

  handleEnlargeRoute() {
    this.router.navigate([
      '/dashboard/viewproject/' +
        this.viewProjectService.getProjectRole() +
        this.projectId +
        '/filter/' +
        this.currentFilter +
        '/page/' +
        this.currentPageNumber +
        '/enlarge/' +
        this.projectImageIds[this.currentImageNum - 1],
    ]);
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }

  onClickTalentApproveReject(imageData) {
    const imgLeft = Utils.talentRejectionCalculation(
      imageData,
      this.talentUseSetup,
      this.viewSetting,
      this.domainConfig.abbr,
    );
    if (imgLeft <= 0) {
      const warningMessage = Utils.onClickTalentApproveReject(
        imageData,
        this.talentUseSetup,
        this.viewSetting,
        this.domainConfig.abbr,
      );
      this.alertCustom(warningMessage);
    } else {
      // Call web service
      this.projectImageData = this.viewProjectService.mergeWithProjectData(
        this.projectImageData,
      );
      const obj = {
        assetId: imageData.id,
        rejected: imageData.rejected,
        projectId: this.projectId,
        tagId: 0,
      };
      this.viewProjectService
        .putSetTalentActionRejected(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (this.projectAuthority === ProjectRole.ROLE_TALENT) {
            if (res.status) {
              this.viewSetting =
                this.viewProjectService.mergeWithProjectDetailPermissionData(
                  this.viewSetting,
                );
              this.viewSetting.talentProjectInfoDTO = res.talentProjectInfoDTO;
              this.viewSetting.talentStatDTOList = res.talentStatDTOList;
              this.viewProjectService.setProjectDetailPermissionData(
                this.viewSetting,
              );
              const imgIndex = this.projectImageData.assetInfoDTOList
                .map(function (e) {
                  return e.id;
                })
                .indexOf(imageData.id);
              if (imgIndex !== -1) {
                this.projectImageData.assetInfoDTOList[imgIndex].rejected =
                  res.rejected;
                this.onRightClickLeave(
                  this.projectImageData.assetInfoDTOList[imgIndex],
                );
              }
            } else {
              this.alertCustom(this.translate.instant('view-project.alert11'));
            }
          }
        });
    }
  }

  onRightClickPress(imgData: any) {
    if (imgData.rejected) {
      this.mouseHide = false;
    }

    if (imgData.excluded) {
      this.mouseExcluded = false;
    }
  }

  onRightClickLeave(imgData) {
    if (imgData.rejected) {
      this.mouseHide = true;
    }
    if (imgData.excluded) {
      this.mouseExcluded = true;
    }
  }

  ngOnDestroy() {
    setTimeout(() => {
      if (this.isTaggingView1Active) {
        this.viewProjectService.setTagginView1DataEnlarge(true);
      }

      if (this.isTaggingView2Active) {
        this.viewProjectService.setTagginView2DataEnlarge(true);
      }

      if (this.isConflictsActive) {
        this.viewProjectService.setConflictsEnlarge(true);
      }
    }, 200);
    super.ngOnDestroy();
  }
}
