import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'fs-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss',
})
export class OnboardingComponent {
  authenticated: boolean;

  constructor(private route: ActivatedRoute, private userService: UserService) {
    this.authenticated = this.route.snapshot.data.authentication;

    if (this.authenticated) {
      this.userService.handleUserLoginChecks(this.userService.getCurrentUser());
      return;
    }
  }
}
