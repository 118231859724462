import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';

@Injectable({ providedIn: 'root' })
export class PasswordResetInitService {
  constructor(private userService: UserService) {}

  save(login: string): Observable<any> {
    return this.userService.resetPasswordInit(
      login,
      // Provide the base app URL to the authentication API
      `${window.location.origin}/#/`,
    );
  }
}
