import { WebImageSource } from './enum/WebImageSource';

export class UserDetails {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string;
  activated: boolean;
  langKey: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;

  authorities: string[];
  userProfileDTO: UserProfileDTO;
}

export class UserProfileDTO {
  id: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  stateProvince: string;
  tagger: boolean;
  country: string;
  phone: string;
  userTypeId: string;
  userTypeValue: string;
  urlSecureAccess: boolean;
  webImageSource: WebImageSource;
  twoFactorAuthentication: boolean;
  mfaOptionTotp: boolean;
  mfaOptionSms: boolean;
  mfaSmsVerified: boolean;
  mfaRecoveryCodeSeen: boolean;
  mfaPriorityChoice: string;
  onboarded: boolean;
}

export class UserContactProfileDTO {
  fullName: string;
  title: string;
  phoneOffice: string;
  phoneMobile: string;
  phoneAlternate: string;
  email: string;
  email2: string;
  phoneFax: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  website: string;
  password: string;
}
