import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserContactProfileDTO } from '../../shared/models/userdetails.model';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fs-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  isSubmitting = false;
  doNotMatch: string;
  error: string;
  private contactProfile: UserContactProfileDTO;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    const user = this.userService.getCurrentUser();
    if (user.userProfileDTO.onboarded) {
      this.userService.handleUserLoginChecks(user);
      return;
    }

    this.contactProfile = this.route.snapshot.data.contact;
  }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      fullName: [this.contactProfile.fullName, [Validators.required]],
      title: [this.contactProfile.title, []],
      phoneOffice: [this.contactProfile.phoneOffice, []],
      phoneMobile: [this.contactProfile.phoneMobile, []],
      phoneAlternate: [this.contactProfile.phoneAlternate, []],
      email: [this.contactProfile.email, []],
      email2: [this.contactProfile.email2, []],
      phoneFax: [this.contactProfile.phoneFax, []],
      streetAddress: [this.contactProfile.streetAddress, []],
      city: [this.contactProfile.city, []],
      state: [this.contactProfile.state, []],
      zipcode: [this.contactProfile.zipcode, []],
      country: [this.contactProfile.country, []],
      website: [this.contactProfile.website, []],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50),
        ],
      ],
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.profileForm.valid || this.isSubmitting) return;

    this.doNotMatch = null;
    this.error = null;

    const password = this.profileForm.get(['newPassword']).value;
    const confirmPassword = this.profileForm.get(['confirmPassword']).value;
    if (password !== confirmPassword) {
      this.doNotMatch = 'ERROR';
      return;
    }

    this.isSubmitting = true;

    const data = this.profileForm.value;

    // Update the contact record in Central
    this.userService
      .updateContactProfile(data)
      .pipe(
        // Then, mark onboarding complete on the Gateway side
        switchMap(() => this.userService.onboardingComplete(data)),
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
      )
      .subscribe({
        next: (user) => {
          this.userService.handleUserLoginChecks(user);
        },
        error: (err) => {
          this.isSubmitting = false;
          this.apiErrorHandlerService.getHandler().handle(err);
        },
      });
  }
}
