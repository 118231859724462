import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { LauncherService } from '../dashboard/launcher/launcher.service';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from '../dashboard/department/department.service';
import { CurrentStateService } from '../shared/services/current.state.service';
import { ViewProjectService } from '../shared/services/view-project.service';
import { FilterEnum } from '../shared/enum/filter.enum';
import { AlbumType } from '../shared/enum/album-type.enum';
import { UnsubscriberComponent } from '../shared/components/unsubscriber/unsubscriber.component';

interface BreadcrumbModel {
  label: string;
  params?: Params;
  url: string;
  disable: boolean;
}

@Component({
  selector: 'fs-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent
  extends UnsubscriberComponent
  implements OnInit
{
  isGallaryMode = false;
  isFavGallaryMode = false;
  isUseSetupStatus = false;
  gallariesURL: string;
  gallariesDepartmentID: string;
  gallaries: any;
  viewGallaries: any;
  currentState: any;
  breadcrumbs: BreadcrumbModel[];
  previousUrl: string;
  selectedProject: string;
  selectedProjectValue: any = null;
  setUpTitle: string;
  currentFilter: string;
  selectedBatchId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private currentStateService: CurrentStateService,
    private router: Router,
    private launcherService: LauncherService,
    private translate: TranslateService,
    private departmentService: DepartmentService,
    private viewProjectService: ViewProjectService,
  ) {
    super();
    this.breadcrumbs = [];
  }

  ngOnInit() {
    this.currentStateService.getCurrentState
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState) => {
        this.currentState = currentState;
      });

    this.launcherService.selectedProjectNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedProject) => {
        this.selectedProject = selectedProject;
      });

    this.launcherService.selectedProjectGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedProject) => {
        if (selectedProject) {
          this.selectedProjectValue = selectedProject;
        } else {
          this.selectedProjectValue = null;
        }
      });

    this.launcherService.isGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isGallaryMode) => {
        this.isGallaryMode = isGallaryMode;
      });

    this.launcherService.isFavGallaryModeGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isFavGallaryMode) => {
        this.isFavGallaryMode = isFavGallaryMode;
      });

    this.launcherService.isProjectUseSetupStatusGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((isUseSetupStatus: boolean) => {
        this.isUseSetupStatus = isUseSetupStatus;
      });

    this.launcherService.gallariesDepartmentIDGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((gallariesDepartmentID) => {
        this.gallariesDepartmentID = gallariesDepartmentID;
      });

    this.departmentService.setUpNameGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((title) => {
        this.setUpTitle = title;
      });

    this.viewProjectService.currentFilterGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFilterName) => {
        this.currentFilter = currentFilterName;
      });

    this.viewProjectService.selectedBatchId$
      .pipe(takeUntil(this.destroy$))
      .subscribe((batchId) => {
        this.selectedBatchId = batchId;
      });

    // subscribe to the NavigationEnd event
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        // set breadcrumbs
        const root: ActivatedRoute = this.activatedRoute.root;
        const obj = this.getBreadcrumbs(root);

        if (
          obj.length === 1 &&
          (obj[0].label === 'View Project' ||
            obj[0].label === 'Enlarge Image' ||
            obj[0].label === 'Caption Image' ||
            obj[0].label === 'Image Compare' ||
            obj[0].label === 'Draw Image' ||
            obj[0].label === 'Galleries' ||
            obj[0].label === 'View Gallery' ||
            obj[0].label === 'Profile')
        ) {
          this.breadcrumbs = this.breadSwitchCustom(obj[0]);

          this.breadcrumbs[this.breadcrumbs.length - 1].disable = true;
        } else {
          this.breadcrumbs = this.getBreadcrumbs(root);
        }
      });
  }

  breadSwitchCustom(urlItem) {
    let returnArr = [];
    switch (urlItem.label) {
      case 'Galleries':
        returnArr = this.breakUrlGalleries(urlItem);
        break;
      case 'View Gallery':
        returnArr = this.breakUrlViewGallery(urlItem);
        break;
      case 'View Project':
        returnArr = this.breakUrlViewProject(urlItem);
        break;
      case 'Enlarge Image':
        returnArr = this.breakUrlEnlargeAndCaption(urlItem);
        break;
      case 'Caption Image':
        returnArr = this.breakUrlEnlargeAndCaption(urlItem);
        break;
      case 'Draw Image':
        returnArr = this.breakUrlDraw(urlItem);
        break;
      case 'Image Compare':
        returnArr = this.breakUrlImageComapre(urlItem);
        break;
      case 'Album':
        break;
      case 'Profile':
        returnArr = [urlItem];
        break;
    }
    return returnArr;
  }

  breakUrlDraw(urlItem) {
    const urlArr = urlItem.url.split('/');
    let viewProjectUrl = {};
    let drawUrl = {};
    if (urlArr[urlArr.length - 2] === 'draw') {
      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (this.currentFilter.includes(FilterEnum.Batch)) {
          viewProjectUrl = {
            label: this.selectedProject,
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              urlArr[6] +
              '/' +
              urlArr[7] +
              '/' +
              urlArr[8],
            params: {},
            disable: false,
          };
        } else {
          viewProjectUrl = {
            label: 'Images',
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              urlArr[6] +
              '/' +
              urlArr[7] +
              '/' +
              urlArr[8],
            params: {},
            disable: false,
          };
        }
      } else {
        viewProjectUrl = {
          label: this.selectedProject,
          url:
            '/' +
            urlArr[1] +
            '/' +
            urlArr[2] +
            '/' +
            urlArr[4] +
            '/' +
            urlArr[5] +
            '/' +
            urlArr[6] +
            '/' +
            urlArr[7] +
            '/' +
            urlArr[8],
          params: {},
          disable: false,
        };
      }
      drawUrl = {
        label: this.translate.instant('breadcrumb.drawImage'),
        url: urlItem.url,
        params: {},
        disable: false,
      };
    }
    if (this.isGallaryMode || this.isFavGallaryMode) {
      if (
        this.selectedProjectValue === AlbumType.SignOff ||
        this.selectedProjectValue === AlbumType.WideEdit
      ) {
        this.gallaries.disable = false;
        return [this.gallaries, viewProjectUrl, drawUrl];
      } else {
        return [this.gallaries, this.viewGallaries, viewProjectUrl, drawUrl];
      }
    } else {
      return [viewProjectUrl, drawUrl];
    }
  }

  breakUrlGalleries(urlItem) {
    const galleriesUrl = {
      label: this.translate.instant('breadcrumb.galleries'),
      url: urlItem.url,
      params: {},
      disable: false,
    };
    if (this.isGallaryMode) {
      this.gallaries = galleriesUrl;
    }
    return [galleriesUrl];
  }

  breakUrlViewGallery(urlItem) {
    if (this.isFavGallaryMode) {
      const galleriesUrl = {
        label: this.translate.instant('breadcrumb.galleries'),
        url: this.gallariesURL,
        params: {},
        disable: false,
      };
      this.gallaries = galleriesUrl;

      this.disableGalleryNavigation();

      const viewGalleryUrlUrl = {
        label: this.setUpTitle,
        url: urlItem.url,
        params: {},
        disable: false,
      };
      this.viewGallaries = viewGalleryUrlUrl;
      return [this.gallaries, this.viewGallaries];
    } else {
      const galleriesUrl = {
        label: this.translate.instant('breadcrumb.galleries'),
        url: this.gallaries ? this.gallaries.url : undefined,
        params: {},
        disable: false,
      };
      this.gallaries = galleriesUrl;

      const viewGalleryUrlUrl = {
        label: this.setUpTitle,
        url: urlItem.url,
        params: {},
        disable: false,
      };

      if (this.isUseSetupStatus) {
        galleriesUrl.disable = true;
      }

      this.viewGallaries = viewGalleryUrlUrl;
      return [this.gallaries, this.viewGallaries];
    }
  }

  disableGalleryNavigation() {
    const searchWord = 'department';

    if (this.gallaries.url.lastIndexOf(searchWord) !== -1) {
      const lastIndex = this.gallaries.url.lastIndexOf(searchWord);
      const lastLetterIndex = lastIndex + searchWord.length + 1;
      const departmentId = this.gallaries.url.slice(
        lastLetterIndex,
        this.gallaries.url.length,
      );

      if (Number(departmentId) === 0) {
        this.gallaries.disable = true;
      }
    }
  }

  breakUrlViewProject(urlItem) {
    const urlArr = urlItem.url.split('/');

    if (
      this.currentFilter === FilterEnum.ShowAll ||
      this.currentFilter === 'show all' ||
      this.selectedProjectValue === AlbumType.SignOff ||
      this.selectedProjectValue === AlbumType.WideEdit
    ) {
      const viewProjectUrl = {
        label: this.selectedProject,
        url: urlItem.url,
        params: {},
        disable: false,
      };

      if (
        (this.isGallaryMode || this.isFavGallaryMode) &&
        this.selectedProjectValue !== AlbumType.SignOff &&
        this.selectedProjectValue !== AlbumType.WideEdit
      ) {
        const viewGalleryUrlUrl = {
          label: this.setUpTitle,
          url: this.viewGallaries.url,
          params: {},
          disable: false,
        };
        this.viewGallaries = viewGalleryUrlUrl;
      }

      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (
          this.selectedProjectValue === AlbumType.SignOff ||
          this.selectedProjectValue === AlbumType.WideEdit
        ) {
          this.gallaries.disable = false;
          return [this.gallaries, viewProjectUrl];
        } else {
          return [this.gallaries, this.viewGallaries, viewProjectUrl];
        }
      } else {
        return [viewProjectUrl];
      }
    } else {
      const filterURL = {
        label: this.translate.instant('breadcrumb.filter'),
        url: urlItem.url,
        params: {},
        disable: false,
      };
      let viewProjectUrl;
      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (this.currentFilter.includes(FilterEnum.Batch)) {
          viewProjectUrl = {
            label: this.selectedProject,
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              'showall' +
              '/' +
              urlArr[7] +
              '/1',
            params: {},
            disable: false,
          };
        } else {
          viewProjectUrl = {
            label: 'Images',
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              'showall' +
              '/' +
              urlArr[7] +
              '/1',
            params: {},
            disable: false,
          };
        }
      } else if (
        this.selectedBatchId ||
        this.currentFilter.includes('prioritypix')
      ) {
        viewProjectUrl = {
          label: this.setUpTitle,
          url:
            '/' +
            urlArr[1] +
            '/' +
            'viewgallery' +
            '/' +
            this.selectedProjectValue,
          params: {},
          disable: false,
        };
      } else {
        viewProjectUrl = {
          label: this.selectedProject,
          url:
            '/' +
            urlArr[1] +
            '/' +
            urlArr[2] +
            '/' +
            urlArr[4] +
            '/' +
            urlArr[5] +
            '/' +
            'showall' +
            '/' +
            urlArr[7] +
            '/1',
          params: {},
          disable: false,
        };
      }

      if (
        (this.isGallaryMode || this.isFavGallaryMode) &&
        this.selectedProjectValue !== AlbumType.SignOff &&
        this.selectedProjectValue !== AlbumType.WideEdit
      ) {
        const viewGalleryUrlUrl = {
          label: this.setUpTitle,
          url: this.viewGallaries.url,
          params: {},
          disable: false,
        };
        this.viewGallaries = viewGalleryUrlUrl;
      }
      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (
          this.selectedProjectValue === AlbumType.SignOff ||
          this.selectedProjectValue === AlbumType.WideEdit
        ) {
          this.gallaries.disable = false;
          return [this.gallaries, viewProjectUrl];
        } else {
          return [this.gallaries, this.viewGallaries, viewProjectUrl];
        }
      } else {
        return [viewProjectUrl, filterURL];
      }
    }
  }

  breakUrlImageComapre(urlItem) {
    const urlArr = urlItem.url.split('/');
    let viewProjectUrl = {};
    if (this.isGallaryMode || this.isFavGallaryMode) {
      if (this.currentFilter.includes(FilterEnum.Batch)) {
        viewProjectUrl = {
          label: this.selectedProject,
          url:
            '/' +
            urlArr[1] +
            '/' +
            urlArr[2] +
            '/' +
            urlArr[4] +
            '/' +
            urlArr[5] +
            '/' +
            urlArr[6] +
            '/' +
            urlArr[7] +
            '/' +
            urlArr[8],
          params: {},
          disable: false,
        };
      } else {
        viewProjectUrl = {
          label: 'Images',
          url:
            '/' +
            urlArr[1] +
            '/' +
            urlArr[2] +
            '/' +
            urlArr[4] +
            '/' +
            urlArr[5] +
            '/' +
            urlArr[6] +
            '/' +
            urlArr[7] +
            '/' +
            urlArr[8],
          params: {},
          disable: false,
        };
      }
    } else {
      viewProjectUrl = {
        label: this.selectedProject,
        url:
          '/' +
          urlArr[1] +
          '/' +
          urlArr[2] +
          '/' +
          urlArr[4] +
          '/' +
          urlArr[5] +
          '/' +
          urlArr[6] +
          '/' +
          urlArr[7] +
          '/' +
          urlArr[8],
        params: {},
        disable: false,
      };
    }
    const compareUrl = {
      label: this.translate.instant('breadcrumb.imageCompare'),
      url: urlItem.url,
      params: {},
      disable: false,
    };

    if (this.isGallaryMode || this.isFavGallaryMode) {
      if (
        this.selectedProjectValue === AlbumType.SignOff ||
        this.selectedProjectValue === AlbumType.WideEdit
      ) {
        this.gallaries.disable = false;
        return [this.gallaries, viewProjectUrl, compareUrl];
      } else {
        return [this.gallaries, this.viewGallaries, viewProjectUrl, compareUrl];
      }
    } else {
      return [viewProjectUrl, compareUrl];
    }
  }

  breakUrlEnlargeAndCaption(urlItem) {
    const urlArr = urlItem.url.split('/');
    let viewProjectUrl = {};
    let englarUrl = {};
    if (urlArr[urlArr.length - 2] === 'enlarge') {
      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (this.currentFilter.includes(FilterEnum.Batch)) {
          viewProjectUrl = {
            label: this.selectedProject,
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              urlArr[6] +
              '/' +
              urlArr[7] +
              '/1',
            params: {},
            disable: false,
          };
        } else {
          viewProjectUrl = {
            label: 'Images',
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              urlArr[6] +
              '/' +
              urlArr[7] +
              '/1',
            params: {},
            disable: false,
          };
        }
      } else {
        viewProjectUrl = {
          label: this.selectedProject,
          url:
            '/' +
            urlArr[1] +
            '/' +
            urlArr[2] +
            '/' +
            urlArr[4] +
            '/' +
            urlArr[5] +
            '/' +
            'showall' +
            '/' +
            urlArr[7] +
            '/1',
          params: {},
          disable: false,
        };
      }
      englarUrl = {
        label: this.translate.instant('breadcrumb.enlargeImage'),
        url: urlItem.url,
        params: {},
        disable: false,
      };
    } else {
      if (this.isGallaryMode || this.isFavGallaryMode) {
        if (this.currentFilter.includes('batch')) {
          viewProjectUrl = {
            label: this.selectedProject,
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              urlArr[6] +
              '/' +
              urlArr[7] +
              '/1',
            params: {},
            disable: false,
          };
        } else {
          viewProjectUrl = {
            label: 'Images',
            url:
              '/' +
              urlArr[1] +
              '/' +
              urlArr[2] +
              '/' +
              urlArr[4] +
              '/' +
              urlArr[5] +
              '/' +
              urlArr[6] +
              '/' +
              urlArr[7] +
              '/1',
            params: {},
            disable: false,
          };
        }
      } else {
        viewProjectUrl = {
          label: this.selectedProject,
          url:
            '/' +
            urlArr[1] +
            '/' +
            urlArr[2] +
            '/' +
            urlArr[4] +
            '/' +
            urlArr[5] +
            '/' +
            urlArr[6] +
            '/' +
            urlArr[7] +
            '/1',
          params: {},
          disable: false,
        };
      }
      englarUrl = {
        label: this.translate.instant('breadcrumb.captionImage'),
        url: urlItem.url,
        params: {},
        disable: false,
      };
    }
    if (this.isGallaryMode || this.isFavGallaryMode) {
      if (
        this.selectedProjectValue === AlbumType.SignOff ||
        this.selectedProjectValue === AlbumType.WideEdit
      ) {
        this.gallaries.disable = false;
        return [this.gallaries, viewProjectUrl, englarUrl];
      } else {
        return [this.gallaries, this.viewGallaries, viewProjectUrl, englarUrl];
      }
    } else {
      return [viewProjectUrl, englarUrl];
    }
  }

  onClickLabel(breadc) {
    if (
      this.breadcrumbs.length > 1 &&
      this.breadcrumbs[1].label === 'Filter' &&
      breadc.label !== 'Filter' &&
      !this.selectedBatchId &&
      !this.currentFilter.includes('prioritypix')
    ) {
      this.viewProjectService.assignFilter(FilterEnum.ShowAll);
    }
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: BreadcrumbModel[] = [],
  ): BreadcrumbModel[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property 'breadcrumb' is specified on the route
      if (
        !Object.prototype.hasOwnProperty.call(
          child.snapshot.data,
          ROUTE_DATA_BREADCRUMB,
        )
      ) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');

      const urlArr = routeURL.split('/');
      // append route URL to URL
      if (urlArr[0] === 'department' || urlArr[0] === 'profile') {
        url += `/dashboard/${routeURL}`;
      } else if (urlArr[0] === 'viewgallery') {
        if (this.isFavGallaryMode) {
          this.gallariesURL =
            '/dashboard/department/' + this.gallariesDepartmentID;
        }
        url += `/dashboard/${routeURL}`;
      } else {
        url += `/dashboard/viewproject/${routeURL}`;
      }

      // add breadcrumb
      const breadcrumb: BreadcrumbModel = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        params: child.snapshot.params,
        url: url,
        disable: false,
      };
      breadcrumbs.push(breadcrumb);
      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    // we should never get here, but just in case
    return breadcrumbs;
  }
}
