import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { AuthRouting } from './auth.routing';
import { LoginComponent } from './login/login.component';
import { PasswordLoginComponent } from './password-login/password-login.component';
import { PasswordResetInitComponent } from './password-reset/init/password-reset-init.component';
import { PasswordResetFinishComponent } from './password-reset/finish/password-reset-finish.component';
import { MfaLoginOptionsComponent } from './mfa-login-options/mfa-login-options.component';
import { MfaRecoveryCodeComponent } from './mfa-recovery-code/mfa-recovery-code.component';
import { MfaSmsComponent } from './mfa-sms/mfa-sms.component';
import { MfaTOTPComponent } from './mfa-totp/mfa-totp.component';
import { MfaRecoveryCodeResetComponent } from './mfa-recovery-code-reset/mfa-recovery-code-reset.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    AuthRouting,
  ],
  declarations: [
    AuthComponent,
    LoginComponent,
    PasswordLoginComponent,
    PasswordResetInitComponent,
    PasswordResetFinishComponent,
    MfaLoginOptionsComponent,
    MfaRecoveryCodeComponent,
    MfaSmsComponent,
    MfaTOTPComponent,
    MfaRecoveryCodeResetComponent,
    OnboardingComponent,
    ImpersonateComponent,
  ],
})
export class AuthModule {}
