<div class="login-form">
  <form [formGroup]="authForm" (ngSubmit)="submitForm()" autocomplete="off">
    <fieldset [disabled]="isSubmitting">
      <div class="align-items-center d-flex flex-column">
        <input
          formControlName="username"
          type="text"
          name="username"
          placeholder="{{ 'login.username' | translate }}"
          class="login-input"
        />
        <input
          fsAutofocus
          formControlName="password"
          type="password"
          name="password"
          placeholder="{{ 'login.password' | translate }}"
          class="login-input"
          minlength="5"
        />

        <a
          class="forgot-password fs-txt-white-light-color"
          routerLink="/forgot-password"
        >
          {{ 'login.forget' | translate }}</a
        >
      </div>
      <div class="login-btn-container">
        <input
          type="submit"
          class="login-btn"
          value="{{ 'login.button.title' | translate }}"
          [disabled]="!authForm.valid"
        />
        <div class="remember-me-container mx-auto text-right">
          <div
            class="fs-round-checkbox mt-4 d-flex justify-content-center align-items-center"
          >
            <input
              class="remember_check"
              formControlName="rememberMe"
              id="remember-me-checkbox"
              type="checkbox"
              (change)="onRememberChange()"
            />
            <label class="custom-checkbox" for="remember-me-checkbox">{{
                'login.remember' | translate
              }}</label>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
