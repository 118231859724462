import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { switchMap } from 'rxjs/operators';
import * as QRCode from 'qrcode';
import { UserDetails } from '../../shared/models/userdetails.model';

@Component({
  selector: 'fs-mfa-setup-totp',
  templateUrl: './mfa-setup-totp.component.html',
  styleUrl: './mfa-setup-totp.component.scss',
})
export class MfaSetupTotpComponent implements OnInit {
  @ViewChild('qrCodeCanvas', { static: true }) qrCodeCanvas: ElementRef;

  totpForm: FormGroup;
  isSubmitting = false;
  showError = false;
  qrCodeUrl: string;
  secretState: string;
  user: UserDetails;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    this.user = this.userService.getCurrentUser();

    this.qrCodeUrl = history.state.qrCodeUrl;
    this.secretState = history.state.secretState;

    if (!this.qrCodeUrl || !this.secretState) {
      // Redirect if no qr code present
      this.router.navigate(['/setup/mfa']);
    }
  }

  ngOnInit(): void {
    this.totpForm = this.fb.group({
      code: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    });

    // Auto-submit when 6 digits are entered
    this.totpForm.get('code').valueChanges.subscribe((value) => {
      if (value.length === 6) {
        this.onSubmit();
      }
    });

    // Generate the QRCode on the canvas
    QRCode.toCanvas(
      this.qrCodeCanvas.nativeElement,
      this.qrCodeUrl,
      {
        errorCorrectionLevel: 'M',
        width: 250,
        margin: 2,
      },
      (error) => {
        if (error) {
          console.error('Error generating QR code:', error);
          this.apiErrorHandlerService
            .getHandler()
            .alertCustom('Failed to generate QR code');
        }
      },
    );
  }

  formatInput(event: any): void {
    const input = event.target;
    let value = input.value.replace(/\D/g, '').substring(0, 6);
    input.value = value;
    this.totpForm.patchValue({ code: value }, { emitEvent: true });
  }

  async onSubmit(): Promise<void> {
    if (!this.totpForm.valid || this.isSubmitting) return;

    this.isSubmitting = true;
    this.showError = false;

    const code = this.totpForm.get('code').value;

    // Finish TOTP enrollment
    this.userService
      .mfaEnrollTotpFinish(this.secretState, code)
      .pipe(
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
      )
      .subscribe({
        next: () => {
          if (!this.user.userProfileDTO.twoFactorAuthentication) {
            this.router.navigate(['/setup/mfa']);
          } else {
            this.router.navigate(['/dashboard/profile']);
          }
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.status === 403) {
            this.showError = true;
            this.totpForm.patchValue({ code: '' });
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
  }
}
