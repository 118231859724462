import { ApiService } from './api.service';
import { ImagecaptionService } from '../../dashboard/project/dashboard-shared/left-menu/menus/administration/imagecaption-report/imagecaption-report.service';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  firstValueFrom,
  interval,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthServerProvider } from './auth.jwt.service';
import { AppSettings } from '../../appSettings/appSettings';
import { ViewProjectService } from './view-project.service';
import { SidebarService } from '../../dashboard/project/sidebar-service/sidebar-service';
import { LauncherService } from '../../dashboard/launcher/launcher.service';
import { PaginationService } from '../../dashboard/project/dashboard-shared/pagination/pagination.service';
import { UserDataService } from './userdata.service';
import * as XLSX from 'xlsx';
import { utils, WorkBook, write } from 'xlsx';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import { ApiImageService } from '../../api-image.service';
import { environment } from '../../../environments/environment';
import { AlbumColorTagsService } from './album-color-tags.service';
import { UserContactProfileDTO, UserDetails } from '../models/userdetails.model';
import { DomainConfig } from '../models/domainConfig.model';
import { SelectedImagesStore } from '../store/selected-images-store';
import { LastSelectedImageStore } from '../store/last-selected-image-store';
import { UserRole } from '../enum/user-role.enum';
import { FilterEnum } from '../enum/filter.enum';
import { parseJwt } from '../utils/jwt';
import { LocalStorageService } from 'ngx-webstorage';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { NGXLogger } from 'ngx-logger';
import { IpService } from './ip-service.service';
import { LoaderService } from './loader.service';
import { AppConfig } from '../../app.configuration';

@Injectable({
  // Ensure that only one singleton exists for BehaviorSubjects
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<UserDetails>(
    new UserDetails(),
  );
  public currentUserGlobal = this.currentUserSubject.asObservable();
  /**
   *   todo try to merge this subject with getCurrentUserAuthority
   *   todo probably refactor whole logic.
   *   todo We can reset authority once, then we can just overwrite it on each call, but do not reset to ROLE_EXTERNAL
   * @private
   */
  private userAuthority = new BehaviorSubject<string>(UserRole.ROLE_EXTERNAL);
  userAuthority$ = this.userAuthority.asObservable();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private isSelectionDisable = new BehaviorSubject<any>(1);
  public isSelectionDisableGlobal = this.isSelectionDisable.asObservable();

  private isUpdatingSession = false;
  private refreshTokenLock = Promise.resolve();
  private refreshTokenErrorStatus = new BehaviorSubject<any>(null);
  private refreshTokenTimer = interval(AppConfig.TIME_OUT.REFRESH_TOKEN);
  private refreshTokenSubscription: Subscription;

  private inactivityLogout = new BehaviorSubject<any>(false);

  private authorizationUrls = ['#/reset-password/', '#/onboarding/', '#/impersonate/'];

  constructor(
    private apiService: ApiService,
    private jwtService: AuthServerProvider,
    private router: Router,
    private viewProjectService: ViewProjectService,
    private sidebarService: SidebarService,
    private paginationService: PaginationService,
    private launcherService: LauncherService,
    private userDataService: UserDataService,
    private imagecaptionService: ImagecaptionService,
    private apiImageService: ApiImageService,
    private albumColorTagsService: AlbumColorTagsService,
    private selectedImagesStore: SelectedImagesStore,
    private lastSelectedImageStore: LastSelectedImageStore,
    private localStorage: LocalStorageService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private logger: NGXLogger,
    private ipService: IpService,
    private loaderService: LoaderService,
  ) {}

  // Check if we're loading an authorizing URL like onboarding or impersonation
  isAuthorizingPage(): boolean {
    for (const auth of this.authorizationUrls) {
      if (location.hash.startsWith(auth)) {
        return true;
      }
    }

    return false;
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    const isAuthorizing = this.isAuthorizingPage();

    // If JWT detected, attempt to get & store user's info
    // If we're authorizing the user on this page, remove the existing session
    if (this.jwtService.getToken() && !isAuthorizing) {
      // On page load, if we aren't configured to resume the session, send the user back to the login screen
      if (!environment.resumeSessionOnPageLoad) {
        this.router.navigate(['/']);
        return false;
      } else {
        // Otherwise, we have our JWT, and need to fetch our profile
        this.loaderService.displayLoader(true);
        this.activateSession(true).subscribe({
          next: () => {
            this.loaderService.displayLoader(false);

            // TODO: resuming into the dashboard needs to load the projects like the launcher does
          },
          error: (err) => {
            this.loaderService.displayLoader(false);

            // If resuming our session resulted in 401, it's likely expired
            if (err.status === 401) {
              this.purgeAuth();
              this.router.navigate(['/']);
            } else {
              this.apiErrorHandlerService.getHandler().handle(err);
            }
          },
        });
        return true;
      }
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
      return isAuthorizing;
    }
  }

  watchRefreshToken() {
    this.stopWatchingRefreshToken();

    this.refreshTokenSubscription = this.refreshTokenTimer.subscribe(() => {
      const refresh_token = this.jwtService.getRefreshToken();

      if (!refresh_token) {
        this.stopWatchingRefreshToken();
        return;
      } else if (this.getUpdatingSessionStatus()) {
        // If we're attempting to update the session, don't refresh
        return;
      }

      this.refreshAuth({ refresh_token }).subscribe({
        next: () => {},
        error: (err) => {
          // If the subscription was already cancelled, we may have just logged out
          if (this.refreshTokenSubscription === null) return;

          this.logger.log(err);
          if (err.status === 403) {
            this.stopWatchingRefreshToken();
            this.setRefreshTokenErrorStatus(err.status);
            this.router.navigateByUrl('/');
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
    });
  }

  stopWatchingRefreshToken() {
    if (this.refreshTokenSubscription) {
      this.refreshTokenSubscription.unsubscribe();
      this.refreshTokenSubscription = null;
    }
  }

  // Examine the roles in the current token to see if we're fully authenticated
  testIsFullyAuthenticated() {
    try {
      const authToken = parseJwt(this.jwtService.getToken());
      return !!authToken?.authenticated;
    } catch (err) {
      console.error("Failed to parse JWT token: ", err);
    }

    return true;
  }

  // After fetching the user profile, determine if:
  // 1. There is a 2fa challenge required
  // 2. If MFA recovery codes should be seen
  // 3. If onboarding is still pending
  // After all checks complete, the user can go to the dashboard
  handleUserLoginChecks(user) {
    if (
      user.userProfileDTO.twoFactorAuthentication &&
      !this.testIsFullyAuthenticated()
    ) {
      // The JWT token is for a partially authenticated session
      return this.router.navigate(['/mfa-challenge/options']);
    } else if (
      user.userProfileDTO.twoFactorAuthentication &&
      !user.userProfileDTO.mfaRecoveryCodeSeen
    ) {
      // The user hasn't seen their recovery codes yet (because they were reset for example)
      return firstValueFrom(
        // Reset the recovery codes
        this.mfaEnrollRecoveryCodeReset().pipe(
          // And show them to the user
          switchMap((data) =>
            this.router.navigate(['/recovery-code-reset'], {
              state: { codes: data.recoveryCodes },
            }),
          ),
        ),
      );
    } else if (!user.userProfileDTO.onboarded) {
      // The user hasn't completed onboarding
      this.router.navigate(['/setup/profile']);
    } else if (!user.userProfileDTO.twoFactorAuthentication) {
      // The user hasn't enabled 2fa yet
      return this.router.navigate(['/setup/mfa']);
    } else {
      return this.router.navigateByUrl('/dashboard');
    }
  }

  setAuth(data) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(data.id_token);
    if (data.refresh_token) {
      this.jwtService.saveRefreshToken(data.refresh_token);
    }
  }

  setCurrentUser(user: UserDetails) {
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  setUpdatingSessionStatus(status) {
    this.isUpdatingSession = status;
  }

  getUpdatingSessionStatus() {
    return this.isUpdatingSession;
  }

  getRefreshTokenLock() {
    return this.refreshTokenLock;
  }

  setRefreshTokenErrorStatus(status) {
    this.refreshTokenErrorStatus.next(status);
  }

  setInactivityLogout(status) {
    this.inactivityLogout.next(status);
  }

  getRefreshTokenErrorStatus(): number {
    return this.refreshTokenErrorStatus.getValue();
  }

  getInactivityLogout(): number {
    return this.inactivityLogout.getValue();
  }

  updateWebImageSrc(user: UserDetails) {
    if (user.userProfileDTO && user.userProfileDTO.webImageSource) {
      // checking that webImageSource received
      const index = user.userProfileDTO.webImageSource - 1;
      if (environment.internalBaseUrl[index]) {
        // checking that internalBaseUrl has value with webImageSource index
        this.apiImageService.setInternalBaseUrl(
          environment.internalBaseUrl[index],
        );
      }
    }
  }

  getCurrentUser(): UserDetails {
    return this.currentUserSubject.value;
  }

  mergeWithCurrentUser(data: UserDetails): UserDetails {
    return { ...data, ...this.getCurrentUser() };
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    this.jwtService.destroyRefreshToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new UserDetails());
    this.viewProjectService.resetCurrentProjectAuthority();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Remove Project Data
  }

  getAuthenticationStatus() {
    return this.isAuthenticatedSubject;
  }

  attemptAuth(type, credentials): Observable<any> {
    return this.apiService.post(type, credentials).pipe(
      map((data) => {
        if (data.id_token) {
          this.setAuth(data);
        }
        return data;
      }),
    );
  }

  refreshAuth(refreshToken): Observable<any> {
    // Acquire the refresh lock
    let resolve;
    this.refreshTokenLock = new Promise((res) => {
      resolve = res;
    });

    const url = AppSettings.REFRESH_TOKEN;
    return this.apiService.post(url, refreshToken).pipe(
      map((data) => {
        if (Object.prototype.hasOwnProperty.call(data, 'status')) {
          if (!data.status) {
            this.purgeAuth();
            this.router.navigateByUrl('/');
          } else {
            if (data.id_token) {
              this.setAuth(data);
            }
            return data;
          }
        } else {
          if (data.id_token) {
            this.setAuth(data);
          }
          return data;
        }
      }),
      // Release the refresh lock
      finalize(() => resolve()),
    );
  }

  // After authenticating and storing our session/refresh tokens
  // We need to periodically refresh our session token using the refresh token
  activateSession(silently?: boolean) {
    // First, initialize other services like the IP service
    this.ipService.getBrowserInfo();
    this.ipService
      .receiveUserIPAddress()
      .then((resp) => resp.json())
      .then((res) => {
        this.ipService.setUserIPAddress(res.ip);
      });

    // Start refreshing the session token
    this.watchRefreshToken();

    // And finally, fetch our profile and to bootstrap the dashboard
    return this.userDetail('api/users/user').pipe(
      tap((user) => {
        if (user.activated) {
          this.viewProjectService.setUserSecureAccess(
            user.userProfileDTO.urlSecureAccess,
          );
        }
      }, (err) => {
        // If we get a 401 trying to activate the session, it's probably expired
        if (err.status === 401) {
          this.stopWatchingRefreshToken();
        }
      }),
      switchMap((user) =>
        !silently ? this.handleUserLoginChecks(user) : of(true),
      ),
    );
  }

  // Alternate authorization paths:
  // A user clicked an onboarding link in an email
  authorizeFromOnboardingKey(key: string): Promise<any> {
    // Since we expect to call this from the initial route resolve, ensure the loader is shown
    this.loaderService.displayLoader(true);
    return firstValueFrom(
      this.attemptAuth('api/authenticate-onboarding-key', { key }).pipe(
        switchMap(() => this.activateSession(true)),
        catchError((error) => {
          console.error(`Unable to authorize onboarding key: ${error.message}`);
          return of(null);
        }),
        finalize(() => {
          this.loaderService.displayLoader(false);
        }),
      ),
    );
  }

  // An admin is entering the app and has an impersonation token
  authorizeFromImpersonationToken(token: string): Promise<any> {
    // Since we expect to call this from the initial route resolve, ensure the loader is shown
    this.loaderService.displayLoader(true);
    return firstValueFrom(
      this.attemptAuth('api/authenticate-impersonation-token', { key: token }).pipe(
        switchMap(() => this.activateSession(true)),
        catchError((error) => {
          console.error(
            `Unable to authorize impersonation token: ${error.message}`,
          );
          return of(null);
        }),
        finalize(() => {
          this.loaderService.displayLoader(false);
        }),
      ),
    );
  }

  changePassword(currentPassword, newPassword) {
    const url = AppSettings.CHANGE_PASSWORD;
    return this.apiService
      .post(url, { currentPassword, newPassword })
      .pipe(map((data) => data));
  }

  resetPasswordInit(login, sendingUrl) {
    const url = AppSettings.RESET_PASSWORD_INIT;
    return this.apiService
      .post(url, { login, sendingUrl })
      .pipe(map((data) => data));
  }

  resetPasswordFinish(key, newPassword) {
    const url = AppSettings.RESET_PASSWORD_FINISH;
    return this.apiService
      .post(url, { key, newPassword })
      .pipe(map((data) => data));
  }

  mfaChallengeSmsInit() {
    const url = AppSettings.MFA_CHALLENGE_SMS_INIT;
    return this.apiService.post(url).pipe(map((data) => data));
  }

  mfaChallengeSmsVerify(code: string) {
    const url = AppSettings.MFA_CHALLENGE_SMS_VERIFY;
    return this.apiService.post(url, { code }).pipe(map((data) => data));
  }

  mfaChallengeTotpVerify(code: string) {
    const url = AppSettings.MFA_CHALLENGE_TOTP_VERIFY;
    return this.apiService.post(url, { code }).pipe(map((data) => data));
  }

  mfaChallengeRecoveryCodeVerify(code: string) {
    const url = AppSettings.MFA_CHALLENGE_RECOVERY_CODE_VERIFY;
    return this.apiService.post(url, { code }).pipe(map((data) => data));
  }

  mfaEnrollSmsInit(phoneNumber: string) {
    const url = AppSettings.MFA_ENROLL_SMS_INIT;
    return this.apiService.post(url, { phoneNumber }).pipe(map((data) => data));
  }

  mfaEnrollSmsFinish(phoneNumber: string, code: string) {
    const url = AppSettings.MFA_ENROLL_SMS_FINISH;
    return this.apiService
      .post(url, { phoneNumber, code })
      .pipe(map((data) => data));
  }

  mfaEnrollTotpInit() {
    const url = AppSettings.MFA_ENROLL_TOTP_INIT;
    return this.apiService.post(url).pipe(map((data) => data));
  }

  mfaEnrollTotpFinish(totpSecret: string, code: string) {
    const url = AppSettings.MFA_ENROLL_TOTP_FINISH;
    return this.apiService
      .post(url, { totpSecret, code })
      .pipe(map((data) => data));
  }

  mfaEnrollRecoveryCodeReset() {
    const url = AppSettings.MFA_ENROLL_RECOVERY_CODE_RESET;
    return this.apiService.post(url).pipe(map((data) => data));
  }

  mfaEnrollRecoveryCodeConfirm() {
    const url = AppSettings.MFA_ENROLL_RECOVERY_CODE_CONFIRM;
    return this.apiService.post(url).pipe(map((data) => data));
  }

  mfaEnrollConfigurePriority(priorityChoice: string) {
    const url = AppSettings.MFA_ENROLL_CONFIGURE_PRIORITY;
    return this.apiService
      .post(url, { priorityChoice })
      .pipe(map((data) => data));
  }

  mfaEnrollComplete() {
    const url = AppSettings.MFA_ENROLL_COMPLETE;
    return this.apiService.post(url).pipe(map((data) => data));
  }

  getContactProfile(): Observable<UserContactProfileDTO> {
    const url = AppSettings.USER_CONTACT_PROFILE;
    return this.apiService.get(url).pipe(map((data) => data));
  }

  updateContactProfile(userProfile: UserContactProfileDTO) {
    const url = AppSettings.USER_CONTACT_PROFILE;
    return this.apiService.post(url, userProfile).pipe(map((data) => data));
  }

  updateContactProfilePhone(phoneNumber: string) {
    const url = AppSettings.UPDATE_CONTACT_PROFILE_PHONE;
    return this.apiService.post(url, { phoneNumber }).pipe(map((data) => data));
  }

  onboardingComplete(userProfile: UserContactProfileDTO) {
    const url = AppSettings.ONBOARDING_COMPLETE;
    return this.apiService
      .post(url, userProfile)
      .pipe(map((data) => data));
  }

  userDetail(type): Observable<UserDetails> {
    return this.apiService.get(type).pipe(
      map((data) => {
        this.setCurrentUser(data);
        this.setUserAuthority(data.authorities[0]);
        this.updateWebImageSrc(data);
        this.viewProjectService.setCurrentUserId(data.id);
        this.viewProjectService.setCurrentUserName(
          data.firstName + ' ' + data.lastName,
        );
        return data;
      }),
    );
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<UserDetails> {
    return this.apiService.put('/user', { user }).pipe(
      map((data) => {
        // Update the currentUser observable
        this.currentUserSubject.next(data);
        return data;
      }),
    );
  }

  signOut(refreshToken) {
    const url = AppSettings.LOG_OUT;
    return this.apiService.post(url, refreshToken).pipe(map((data) => data));
  }

  setIsSelectionDisable(inc) {
    this.isSelectionDisable.next(inc);
  }

  resetData() {
    this.selectedImagesStore.clear();
    this.viewProjectService.resetProjectData();
    this.viewProjectService.resetProjectImageIDs();
    this.viewProjectService.resetTotalImageCount();
    this.viewProjectService.resetProjectDetailPermissionData();
    this.viewProjectService.resetExecutiveAlbumID();
    this.viewProjectService.reSetSensitiveID();
    this.viewProjectService.resetAlbumsInfo();
    this.sidebarService.resetAlbumListData();
    this.sidebarService.resetAlbumImageData();
    this.viewProjectService.reSetWideEditalbumsInfo();
    this.sidebarService.setDeSelectedAlbum({});
    this.sidebarService.setUpdatedTaggingAlbum({});
    this.sidebarService.setUpdatedTaggingSignOffAlbum({});
    this.sidebarService.setSelectedAlbumName({});
    this.sidebarService.removeHoverAlbum();
    this.sidebarService.removeHoverAlbumClicked();
    this.lastSelectedImageStore.clear();
    this.paginationService.setPaginationIndex(0);
    this.viewProjectService.setExecutiveAlbum(false);
    this.viewProjectService.isPiorityPixDataLoaded.next(false);
    this.viewProjectService.priorityPixImageCount.next(0);
    this.viewProjectService.setCurrentPageNumber(null);
    this.viewProjectService.setTotalPageNumber(0);
    this.launcherService.resetSelectedProject();
    this.viewProjectService.isProjectDataLoadedFromNoteReports.next(false);
    this.viewProjectService.setCurrentFilter(FilterEnum.ShowAll);
    this.viewProjectService.resetProjectID();
    this.albumColorTagsService.setIsColorPanelEnabled(false);
    this.userDataService.resetUserData();
    this.viewProjectService.setActivatedLeftTabIndex(-1);
    this.sidebarService.setUpdatedTaggingSignOffAlbum({});
    this.sidebarService.setUpdatedTaggingWideEditAlbum({});
    this.viewProjectService.priorityPixHandling.next(false);
    this.imagecaptionService.reSetImagecaptionReport();
    this.resetUserAuthority();
    this.viewProjectService.resetCurrentProjectAuthority();
    this.viewProjectService.resetSelectedProjectData();
  }

  setUserAuthority(userRole: string): void {
    this.userAuthority.next(userRole);
  }

  resetUserAuthority(): void {
    this.userAuthority.next('');
  }

  resetGalleryData() {
    this.launcherService.setIsGallaryModeStatus(false);
    this.launcherService.setIsFavGallaryModeStatus(false);
    this.launcherService.setIsProjectUseSetupStatus(false);
  }

  exportReport(reportName, arrReportData, reportFileName) {
    const wsName = reportName;
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = utils.json_to_sheet(arrReportData);
    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;
    const wbout = write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        // tslint:disable-next-line: no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      this.viewProjectService.getProjectDetailPermissionData()
        .projectGroupName +
        ' ' +
        reportFileName,
    );
  }

  exportReportApprovalReport(reportName, arrReportData, reportFileName) {
    const wsName = reportName;
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws: any = XLSX.utils.json_to_sheet(arrReportData);
    wb.SheetNames.push(wsName);
    wb.Sheets[wsName] = ws;
    const wbout = write(wb, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        // tslint:disable-next-line: no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      reportFileName +
        '_' +
        this.viewProjectService.getProjectDetailPermissionData().projectName +
        '.xlsx',
    );
  }

  exportPDFData(jsonData, columnNames?) {
    printJS({ printable: jsonData, properties: columnNames, type: 'json' });
  }

  exportPDFDataInHorizontalOrientation(jsonData, columnNames?) {
    printJS({
      printable: jsonData,
      properties: columnNames,
      type: 'json',
      gridHeaderStyle: 'border: 2px solid #282828; font-size: 10px',
      style: '@page { size: Letter landscape; }',
      gridStyle: 'border: 2px solid #282828; font-size: 10px',
    });
  }

  getColumnsName(columObj) {
    return Object.keys(columObj);
  }

  getFullUserName(): string {
    return (
      this.getCurrentUser().firstName + ' ' + this.getCurrentUser().lastName
    );
  }

  isAdminWithTaggerRole(): boolean {
    return (
      this.getCurrentUser().authorities[0] === UserRole.ROLE_ADMIN &&
      this.getCurrentUser().userProfileDTO.tagger
    );
  }

  postDomainData(data): Observable<any> {
    const path = AppSettings.DOMAIN_DATA;
    // This can be performed silently: App's ngOnInit sets it to false or waits for the session to resume
    return this.apiService.post(path, data, true).pipe(
      map((res) => {
        return res as DomainConfig;
      }),
    );
  }
}
