import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';
import { SidebarService } from '../../sidebar-service/sidebar-service';
import { LeftMenuComponent } from './left-menu.component';
import { ApplyExifService } from './menus/administration/apply-exif/apply-exif.service';
import { ApplyExifComponent } from './menus/administration/apply-exif/apply-exif.component';
import { ExecutiveProjectinfoComponent } from './executive-user/executive-projectinfo/executive-projectinfo.component';
import { WideEditAdministrationComponent } from './menus/wide-edit-administration/wide-edit-administration.component';
import { SendEmailNotificationComponent } from './menus/wide-edit-administration/send-email-notification/send-email-notification.component';
import { SignoffAdministrationComponent } from './menus/signoff-administration/signoff-administration.component';
import { SelectRecepient } from './menus/wide-edit-administration/select-recepient/select-recepient.component';
import { PasswordComponent } from './menus/password/password.component';
import { WideEditAlbumDialogComponent } from './executive-user/wide-edit-album-dialog/wide-edit-album-dialog.component';
import { SideMenusComponent } from './menus/sidemenus.component';
import { AdministrationComponent } from './menus/administration/administration.component';
import { ActoreapprovalReportComponent } from './menus/administration/actoreapproval-report/actoreapproval-report.component';
import { FilteredfilenameListComponent } from './menus/administration/filteredfilename-list/filteredfilename-list.component';
import { ImageExclusivesComponent } from './menus/administration/image-exclusives/image-exclusives.component';
import { ImagenotesReportComponent } from './menus/administration/imagenotes-report/imagenotes-report.component';
import { ProjectsummaryReportComponent } from './menus/administration/projectsummary-report/projectsummary-report.component';
import { ActoreidReportComponent } from './menus/administration/actoreid-report/actoreid-report.component';
import { AdvanceImageCorrectComponent } from './menus/administration/advanceimage-tagging/advanceImage-correct/advanceImage-correct.component';
import { AdvanceimageTaggingComponent } from './menus/administration/advanceimage-tagging/advanceimage-tagging.component';
import { Taggingview2Component } from './menus/administration/taggingview-2/taggingview-2.component';
import { Taggingview1Component } from './menus/administration/taggingview-1/taggingview-1.component';
import { ImagecaptionReportComponent } from './menus/administration/imagecaption-report/imagecaption-report.component';
import { SelectedfilenameListComponent } from './menus/administration/selectedfilename-list/selectedfilename-list.component';
import { FsdeliveryComponent } from './menus/administration/fs-delivery/fsdelivery.component';
import { ApprovalReportComponent } from './menus/administration/actoreapproval-report/approval-report/approval-report.component';
import { RejectionToggleComponent } from './menus/administration/rejection-toggle/rejection-toggle.component';
import { AddExclusivesComponent } from './menus/administration/image-exclusives/add-exclusives/add-exclusives.component';
import { NotesReportComponent } from './menus/administration/imagenotes-report/notes-report/notes-report.component';
import { FinalizeUnfinalizeComponent } from './menus/administration/finalize-unfinalize/finalize-unfinalize.component';
import { CaptionReportComponent } from './menus/administration/imagecaption-report/caption-report/caption-report.component';
import { BatchManagementComponent } from './menus/administration/batch-management/batch-management.component';
import { TalentManagementComponent } from './menus/administration/talent-management/talent-management.component';
import { ProjectsummaryreportService } from './menus/administration/projectsummary-report/projectsummary-report.service';
import { ImagecaptionService } from './menus/administration/imagecaption-report/imagecaption-report.service';
import { ActorapprovalreportService } from './menus/administration/actoreapproval-report/actoreapproval-report.service';
import { ImagenotesService } from './menus/administration/imagenotes-report/imagenotes-report.service';
import { AdministrationService } from './menus/administration/administration.service';
import { ImageexclusivesService } from './menus/administration/image-exclusives/image-exclusives.service';
import { BatchManagementService } from './menus/administration/batch-management/batch-management.service';
import { TalentManagementService } from './menus/administration/talent-management/talent-management.service';
import { ImageInfoService } from './menus/imageinfo/imageinfo.service';
import { FsDeliveryService } from './menus/administration/fs-delivery/fsdelivery.service';
import { SharedModule } from '../../../../shared/shared.module';
import { CustomFilterComponent } from './customfilter/customfilter.component';
import { TalentCustomFilterComponent } from './talent-user/talent-customfilter/talent-customfilter.component';
import { AdvancesearchComponent } from './advancesearch/advancesearch.component';
import { AlbumsComponent } from './menus/albums/albums.component';
import { AlbumsNamePipe } from './menus/albums/albumsName.pipe';
import { UserNamePipe } from './menus/albums/userName.pipe';
import { EditPermissionComponent } from './menus/albums/edit-permission/edit-permission.component';
import { DeletealbumComponent } from './menus/shared-menus/deletealbum/deletealbum.component';
import { SignoffComponent } from './menus/signoff/signoff.component';
import { WideEditComponent } from './menus/wide-edit/wide-edit.component';
import { SharedMenuComponent } from './menus/shared-menus/shared-menu.component';
import { AddalbumComponent } from './menus/shared-menus/addalbum/addalbum.component';
import { EditalbumComponent } from './menus/shared-menus/editalbum/editalbum.component';
import { RenamealbumComponent } from './menus/shared-menus/renamealbum/renamealbum.component';
import { AuditComponent } from './menus/audit/audit.component';
import { ConflictsComponent } from './menus/audit/conflicts/conflicts.component';
import { RetouchComponent } from './retouch/retouch.component';
import { ProjectInfoComponent } from './projectinfo/projectinfo.component';
import { TalentProjectInfoComponent } from './talent-user/talent-projectinfo/talent-projectinfo.component';
import { PublishImagesComponent } from './menus/audit/publish-images/publish-images.component';
import { NoConflictsComponent } from './menus/audit/no-conflicts/no-conflicts.component';
import { Bypass2ndTaggerComponent } from './menus/audit/bypass-2nd-tagger/bypass-2nd-tagger.component';
import { ClearSingleTagComponent } from './menus/audit/clear-single-tag/clear-single-tag.component';
import { HideImagesComponent } from './menus/audit/hide-images/hide-images.component';
import { UnhideImagesComponent } from './menus/audit/unhide-images/unhide-images.component';
import { AdhocComponent } from './menus/adhoc/adhoc.component';
import { CommentsComponent } from './retouch/comments/comments.component';
import { ContributorsComponent } from './retouch/contributors/contributors.component';
import { WideEditAlbumComponent } from './executive-user/wide-edit-album/wide-edit-album.component';
import { FinalizeImagesComponent } from './talent-user/finalize-images/finalize-images.component';
import { PkoLogoutComponent } from './talent-user/finalize-images/pko-logout/pko-logout.component';
import { FinalizationComponent } from './talent-user/finalize-images/finalization/finalization.component';
import { WideEditShowAllComponent } from './executive-user/wide-edit-showall/wide-edit-showAll.component';
import { ExecutiveNotifyComponent } from './menus/albums/edit-permission/executive-notify/executive-notify.component';
import { TalentProritypixButons } from './talent-user/talent-proritypix-butons/talent-proritypix-butons';
import { TagInfoEnlargeComponent } from './menus/audit/tag-info-enlarge/tag-info-enlarge.component';
import { TalentNoteComponent } from './talent-user/telent-note/talent-note.component';
import { AlbumsService } from './menus/albums/albums.service';
import { EditPermmissionService } from './menus/albums/edit-permission/edit-permission.service';
import { SignoffService } from './menus/signoff/signoff.service';
import { WideEditService } from './menus/wide-edit/wide-edit.service';
import { AlbumssharedService } from './menus/shared-menus/albums-service/albums-shared.service';
import { WideEditAdvanceSearchService } from './executive-user/wide-edit-album/wide-edit-album.service';
import { AdvanceSearchService } from './advancesearch/advancesearch.service';
import { PublishImagesService } from './menus/audit/publish-images/publish-images.service';
import { HideImagesService } from './menus/audit/hide-images/hide-images.service';
import { UnhideImagesService } from './menus/audit/unhide-images/unhide-images.service';
import { ClearSingleTagService } from './menus/audit/clear-single-tag/clear-single-tag.service';
import { ContributorsService } from './retouch/contributors/contributors.service';
import { CommentsService } from './retouch/comments/comments.service';
import { FinalizationService } from './talent-user/finalize-images/finalization/finalization.service';
import { TelentNoteService } from './talent-user/telent-note/telent-note.service';
import { ImageExclusivesDialogComponent } from './menus/administration/image-exclusives/image-exclusives-dialog-component/image-exclusives-dialog-component.component';
import { ActorIdHistoryReportComponent } from './menus/administration/actoreapproval-report/actor-id-history-report/actor-id-history-report.component';
import { MatSortModule } from '@angular/material/sort';
import { ConvertAssetTypesService } from './menus/audit/convert-asset-type/convert-asset-types.service';
import { ConvertAssetTypeComponent } from './menus/audit/convert-asset-type/convert-asset-type.component';
import { MetaDataMenuComponent } from './menus/meta-data-menu/meta-data-menu.component';
import { DuplicateListMenuComponent } from './menus/administration/duplicate-list-menu/duplicate-list-menu.component';
import { OriginalNamesComponent } from './menus/administration/original-names/original-names.component';
import { OriginalNamesReportComponent } from './menus/administration/original-names/original-names-report/original-names-report.component';

@NgModule({
  imports: [
    NgxPaginationModule,
    SharedModule,
    MatSortModule,
  ],
  declarations: [
    LeftMenuComponent,
    CustomFilterComponent,
    TalentCustomFilterComponent,
    SideMenusComponent,
    AdvancesearchComponent,
    AlbumsComponent,
    AlbumsNamePipe,
    UserNamePipe,
    EditPermissionComponent,
    SignoffComponent,
    WideEditComponent,
    SharedMenuComponent,
    AddalbumComponent,
    DeletealbumComponent,
    EditalbumComponent,
    RenamealbumComponent,
    AdministrationComponent,
    AuditComponent,
    ConvertAssetTypeComponent,
    ConflictsComponent,
    RetouchComponent,
    ActoreapprovalReportComponent,
    FilteredfilenameListComponent,
    SelectedfilenameListComponent,
    ImageExclusivesComponent,
    ProjectsummaryReportComponent,
    ImagenotesReportComponent,
    ImagecaptionReportComponent,
    Taggingview1Component,
    Taggingview2Component,
    AdvanceimageTaggingComponent,
    AdvanceImageCorrectComponent,
    ActoreidReportComponent,
    FsdeliveryComponent,
    FinalizeUnfinalizeComponent,
    RejectionToggleComponent,
    ApprovalReportComponent,
    NotesReportComponent,
    CaptionReportComponent,
    AddExclusivesComponent,
    BatchManagementComponent,
    DuplicateListMenuComponent,
    OriginalNamesComponent,
    OriginalNamesReportComponent,
    ApplyExifComponent,
    ProjectInfoComponent,
    TalentProjectInfoComponent,
    PublishImagesComponent,
    NoConflictsComponent,
    Bypass2ndTaggerComponent,
    ClearSingleTagComponent,
    HideImagesComponent,
    UnhideImagesComponent,
    AdhocComponent,
    CommentsComponent,
    ContributorsComponent,
    TalentManagementComponent,
    WideEditAlbumComponent,
    FinalizeImagesComponent,
    FinalizationComponent,
    PkoLogoutComponent,
    ExecutiveProjectinfoComponent,
    WideEditAdministrationComponent,
    SendEmailNotificationComponent,
    WideEditShowAllComponent,
    SignoffAdministrationComponent,
    TalentProritypixButons,
    SelectRecepient,
    ExecutiveNotifyComponent,
    TagInfoEnlargeComponent,
    TalentNoteComponent,
    PasswordComponent,
    WideEditAlbumDialogComponent,
    ImageExclusivesDialogComponent,
    ActorIdHistoryReportComponent,
    MetaDataMenuComponent,
  ],
  providers: [
    SidebarService,
    AlbumsService,
    EditPermmissionService,
    SignoffService,
    WideEditService,
    AlbumssharedService,
    ActorapprovalreportService,
    ProjectsummaryreportService,
    ImagenotesService,
    ImagecaptionService,
    WideEditAdvanceSearchService,
    ApplyExifService,
    AdministrationService,
    ImageexclusivesService,
    BatchManagementService,
    AdvanceSearchService,
    PublishImagesService,
    HideImagesService,
    ConvertAssetTypesService,
    UnhideImagesService,
    ClearSingleTagService,
    ContributorsService,
    CommentsService,
    TalentManagementService,
    FinalizationService,
    ImageInfoService,
    TelentNoteService,
    FsDeliveryService,
  ],
  exports: [
    LeftMenuComponent,
    SideMenusComponent,
    AlbumsComponent,
    EditPermissionComponent,
    AddalbumComponent,
    TalentNoteComponent,
    CaptionReportComponent,
    TalentManagementComponent
  ],
})
export class LeftMenuModule {}
