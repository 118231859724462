import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fs-mfa-finish',
  templateUrl: './mfa-finish.component.html',
  styleUrl: './mfa-finish.component.scss',
})
export class MfaFinishComponent {
  generatedRecoveryCodes: string[] = [];
  isCopied = false;
  isSubmitting = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    const user = this.userService.getCurrentUser();
    if (user.userProfileDTO.twoFactorAuthentication) {
      this.userService.handleUserLoginChecks(user);
      return;
    }

    if (!history.state.codes) {
      this.router.navigateByUrl('/setup/mfa');
      return;
    }

    this.generatedRecoveryCodes = history.state.codes;
  }

  copyAllCodes(): void {
    const codeString = this.generatedRecoveryCodes.join('\n');
    navigator.clipboard
      .writeText(codeString)
      .then(() => {
        this.isCopied = true;

        // Reset copied state after 3 seconds
        setTimeout(() => {
          this.isCopied = false;
        }, 3000);
      })
      .catch((err) => {
        this.apiErrorHandlerService
          .getHandler()
          .alertCustom('Unable to copy to clipboard');
      });
  }

  downloadRecoveryCodes(): void {
    const codeString = this.generatedRecoveryCodes.join('\n');
    const blob = new Blob([codeString], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'recovery-codes.txt';
    link.click();

    window.URL.revokeObjectURL(url);
  }

  async continueToAccount(): Promise<void> {
    if (this.isSubmitting) return;

    this.isSubmitting = true;

    // Complete MFA enrollment
    this.userService
      .mfaEnrollComplete()
      .pipe(
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
      )
      .subscribe({
        next: (user) => {
          // Advance to the dashboard or onboarding if needed
          this.userService.handleUserLoginChecks(user);
        },
        error: (err) => {
          this.isSubmitting = false;
          this.apiErrorHandlerService.getHandler().handle(err);
        },
      });
  }
}
