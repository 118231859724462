<div class="container py-4">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.recoveryCodeReset.title' | translate }}</h4>
        </div>

        <div class="card-body">
          <div class="alert alert-warning" role="alert">
            <strong>{{ 'twoFactor.recoveryCodeReset.important' | translate }}</strong>
            {{ 'twoFactor.recoveryCodeReset.description' | translate }}
          </div>

          <div class="recovery-codes-grid text-monospace">
            <div
              *ngFor="let code of generatedRecoveryCodes; let i = index"
              class="text-left"
            >{{ code }}
            </div>
          </div>

          <div class="action-buttons mt-4 d-flex justify-content-between">
            <button
              class="btn btn-outline-success"
              (click)="copyAllCodes()"
              [disabled]="isCopied"
            >
              <i class="bi bi-clipboard mr-2"></i>
              {{ (isCopied ? 'twoFactor.recoveryCodeReset.copied' : 'twoFactor.recoveryCodeReset.copyCodes') | translate }}
            </button>

            <button
              class="btn btn-outline-info ml-2"
              (click)="downloadRecoveryCodes()"
            >
              <i class="bi bi-download mr-2"></i>
              {{ 'twoFactor.recoveryCodeReset.downloadCodes' | translate }}
            </button>
          </div>
        </div>

        <div class="card-footer text-center">
          <button
            class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
            [disabled]="isSubmitting"
            (click)="continueToAccount()"
          >
            <span
              *ngIf="isSubmitting"
              class="spinner-border spinner-border-sm mr-2"
              role="status"
            ></span>
            {{ 'twoFactor.recoveryCodeReset.button' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
