import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupComponent } from './setup.component';
import { MfaSetupComponent } from './mfa-setup/mfa-setup.component';
import { MfaFinishComponent } from './mfa-finish/mfa-finish.component';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SetupRouting } from './setup.routing';
import { MfaSetupSmsComponent } from './mfa-setup-sms/mfa-setup-sms.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MfaSetupSmsVerifyComponent } from './mfa-setup-sms-verify/mfa-setup-sms-verify.component';
import { SharedModule } from '../shared/shared.module';
import { MfaSetupTotpComponent } from './mfa-setup-totp/mfa-setup-totp.component';
import { ProfileComponent } from './profile/profile.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterOutlet,
    TranslateModule,
    SetupRouting,
    SharedModule,
    MatButtonModule,
  ],
  declarations: [
    SetupComponent,
    MfaSetupComponent,
    MfaFinishComponent,
    MfaSetupSmsComponent,
    MfaSetupSmsVerifyComponent,
    MfaSetupTotpComponent,
    ProfileComponent,
  ],
})
export class SetupModule {}
