import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export function formatPhoneNumber(number: string | undefined | null) {
  if (!number) {
    return '';
  }
  const parsed = phoneUtil.parse(number, 'US');
  if (!parsed) {
    return '';
  }
  return parsed.getCountryCodeOrDefault() === 1
    ? phoneUtil.format(parsed, PhoneNumberFormat.NATIONAL)
    : phoneUtil.format(parsed, PhoneNumberFormat.INTERNATIONAL);
}
