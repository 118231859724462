import { Component } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { UserDetails } from '../../shared/models/userdetails.model';
import { formatPhoneNumber } from '../../shared/utils/phone-number';
import { Router } from '@angular/router';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'fs-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent {
  user: UserDetails;
  isSubmitting = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    this.user = this.userService.getCurrentUser();
  }

  async setupAuthenticator(): Promise<void> {
    if (this.isSubmitting) return;

    this.isSubmitting = true;

    // Initiate the TOTP enrollment flow
    this.userService.mfaEnrollTotpInit().subscribe({
      next: (data) => {
        this.router.navigate(['/setup/mfa/totp'], {
          state: { qrCodeUrl: data.qrCodeUrl, secretState: data.secretState },
        });
      },
      error: (err) => {
        this.isSubmitting = false;
        this.apiErrorHandlerService.getHandler().handle(err);
      },
    });
  }

  async setupSms(): Promise<void> {
    this.router.navigate(['/setup/mfa/sms']);
  }

  async resetRecovery(): Promise<void> {
    if (this.isSubmitting) return;

    this.isSubmitting = true;

    let codes: string[];
    // Reset the recovery codes
    this.userService
      .mfaEnrollRecoveryCodeReset()
      .pipe(
        tap((data) => {
          codes = data.recoveryCodes;
        }),
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
      )
      .subscribe({
        next: () => {
          this.router.navigate(['/recovery-code-reset'], {
            state: { codes },
          });
        },
        error: (err) => {
          this.isSubmitting = false;
          this.apiErrorHandlerService.getHandler().handle(err);
        },
      });
  }

  protected readonly formatPhoneNumber = formatPhoneNumber;
}
