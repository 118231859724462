import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ApiImageService } from './api-image.service';
import { environment } from '../environments/environment';
import { LocationStrategy } from '@angular/common';
import { DomainConfigService } from './shared/services/domain-config.service';
import { UserService } from './shared/services/user.service';
import { LoaderService } from './shared/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { FsCookieService } from './shared/services/fs-cookie.service';
import { CookieBannerService } from './shared/components/cookie-banner/cookie-banner.service';
import { LanguageService } from './shared/services/language.service';
import { DialogService } from './shared/services/dialog/dialog.service';
import { UnsubscriberComponent } from './shared/components/unsubscriber/unsubscriber.component';
import { interval } from 'rxjs';
import { SnackBarService } from './shared/services/snack-bar.service';
import { AppUpdatesService } from './shared/components/app-updates/app-updates.service';
import {ApiErrorHandlerService} from './shared/services/api-error-handler.service';

@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UnsubscriberComponent implements OnInit {
  customeText = 'customeText';
  redirect = false;
  isSelectionDisable = 1;
  availableUrlList: any = [];
  availableBaseUrlList: any = [];
  internalBaseUrlList: any = [];
  isUrlActive: any = false;
  isBaseUrlActive: any = false;
  subscribeTimer: any;
  currentBaseURLIndex = 0;
  public status = null;
  isAuthenticated = false;
  reloadRequireExecuteOnInit = true;
  checkAppVersionInterval = interval(60000);

  constructor(
    private domainConfigService: DomainConfigService,
    private userService: UserService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private loaderService: LoaderService,
    private appUpdatesService: AppUpdatesService,
    private cookieBannerService: CookieBannerService,
    private ref: ChangeDetectorRef,
    private sessionStorage: SessionStorageService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private localStorage: LocalStorageService,
    private languageService: LanguageService,
    private apiImageService: ApiImageService,
    private location: LocationStrategy,
    private cookieService: FsCookieService,
    private dialogService: DialogService,
  ) {
    super();

    this.languageService.setLanguageCookie();
  }

  ngOnInit() {
    this.openCookieBannerDialog();
    this.getDomainData();
    this.availableUrlList = environment.apiUrlList;
    this.availableBaseUrlList = environment.assetBaseUrl;
    this.internalBaseUrlList = environment.internalBaseUrl;
    this.apiImageService.setWebImageUrlGlobal(this.availableUrlList[0]);
    this.apiImageService.setBaseUrlGlobal(this.availableBaseUrlList[0]);
    this.apiImageService.setInternalBaseUrl(this.internalBaseUrlList[0]);

    this.userService.isAuthenticated
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isAuthenticated = status;
      });

    this.cookieService.checkCookieExpiration();
    if (!this.userService.populate()) {
      this.loaderService.displayLoader(false);
    }

    this.checkAppVersionInterval.subscribe((): void => {
      this.getDomainData();
    });

    this.userService.isSelectionDisableGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.isSelectionDisable = res));

    this.userService.setIsSelectionDisable(true);
  }

  reloadRequire(res?) {
    const reloadCompleted = this.sessionStorage.retrieve('reload-completed'); // protection to reload page only once
    const version = res?.version;
    if (!version) {
      return;
    }
    if (version !== environment.version) {
      // if new app version released
      if (!reloadCompleted) {
        // and if was not reloaded before
        this.sessionStorage.store('reload-completed', true); // set true to not reload page second time
        location.reload();
      }
    } else {
      // if app version already match to version from db
      this.sessionStorage.store('reload-completed', false);
      this.localStorage.store('version', version); // store current version to sessionStorage just for information purpose
    }
  }

  openCookieBannerDialog() {
    if (!this.cookieService.isCookieExist()) {
      this.cookieBannerService.open();
      this.cookieService.clearStorageValues();
    }
  }

  getDomainData() {
    let _domainName = window.location.href;

    const indexOfFirst = _domainName.indexOf('#');
    if (indexOfFirst === -1) {
      // TODO this is workaround, remove from BE side check for # in payload
      _domainName = `${_domainName.split('/').slice(0, -1).join('/')}/#/`;
    }
    const data = {
      sendingUrl: _domainName,
    };

    this.userService
      .postDomainData(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res) {
            this.domainConfigService.setIsDomainSuccess(true);
            this.domainConfigService.setDomainConfigData(res);

            if (this.reloadRequireExecuteOnInit) {
              this.reloadRequire(res);
            }

            this.checkApplicationVersion(res.version);
            this.appUpdatesService.setNewFeatureAnnounceVersion(
              res.version,
              false,
            );
            this.reloadRequireExecuteOnInit = false;
          }
        },
        (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
          this.domainConfigService.setIsDomainSuccess(false);
        },
      );
  }

  checkApplicationVersion(version: string): void {
    if (version !== environment.version) {
      this.snackBarService.showSnackBarWarning(
        this.translate.instant('global.appVersionWarning'),
        '',
        45000,
        'right',
        'top',
        'snack-bar-yellow-panel',
      );
    }
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
