<div class="phone-input-container">
  <input
    type="tel"
    id="phone"
    [formControl]="phoneControl"
    [class]="inputClass"
    [ngClass]="ngInputClass"
    (blur)="inputBlur()"
  >
</div>
