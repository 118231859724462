import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDetails } from '../../shared/models/userdetails.model';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { TranslateService } from '@ngx-translate/core';

interface TwoFactorMethod {
  id: string;
  nameKey: string;
  descriptionKey: string;
  icon: string;
  route: string;
  valid: (user: UserDetails) => boolean;
}

@Component({
  selector: 'fs-mfa-login-options',
  templateUrl: './mfa-login-options.component.html',
  styleUrl: './mfa-login-options.component.scss',
})
export class MfaLoginOptionsComponent implements OnInit {
  twoFactorForm: FormGroup;
  user: UserDetails;
  selectedMethod: string;
  allMethods: TwoFactorMethod[] = [
    {
      id: 'sms',
      nameKey: 'twoFactor.method.sms.name',
      descriptionKey: 'twoFactor.method.sms.description',
      icon: 'phone_iphone',
      route: '/mfa-challenge/sms',
      valid: (user: UserDetails) =>
        user.userProfileDTO.mfaOptionSms && user.userProfileDTO.mfaSmsVerified,
    },
    {
      id: 'totp',
      nameKey: 'twoFactor.method.totp.name',
      descriptionKey: 'twoFactor.method.totp.description',
      icon: 'qr_code_2',
      route: '/mfa-challenge/totp',
      valid: (user: UserDetails) => user.userProfileDTO.mfaOptionTotp,
    },
    {
      id: 'recovery',
      nameKey: 'twoFactor.method.recoveryCode.name',
      descriptionKey: 'twoFactor.method.recoveryCode.description',
      icon: 'vpn_key',
      route: '/mfa-challenge/recovery-code',
      valid: () => true,
    },
  ];
  availableMethods: TwoFactorMethod[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private translate: TranslateService,
  ) {
    this.user = this.userService.getCurrentUser();

    if (this.userService.testIsFullyAuthenticated()) {
      this.userService.handleUserLoginChecks(this.user);
      return;
    }

    if (!this.user?.userProfileDTO) {
      this.router.navigateByUrl('/');
      return;
    }
  }

  ngOnInit(): void {
    this.twoFactorForm = this.fb.group({
      selectedMethod: ['', Validators.required],
    });

    this.availableMethods = this.allMethods.filter((method) =>
      method.valid(this.user),
    );

    const priorityIndex = this.availableMethods.findIndex(
      (method) => method.id === this.user.userProfileDTO.mfaPriorityChoice,
    );
    if (priorityIndex > 0) {
      const priorityMethod = this.availableMethods.splice(priorityIndex, 1);
      this.availableMethods.unshift(priorityMethod[0]);
    }
  }

  selectMethod(method: TwoFactorMethod): void {
    this.selectedMethod = method.id;

    if (method.id === 'sms') {
      // Initiate the SMS flow
      this.userService.mfaChallengeSmsInit().subscribe({
        next: () => {
          this.router.navigate([method.route]);
        },
        error: (err) => {
          this.selectedMethod = null;

          if (err.status === 429) {
            this.apiErrorHandlerService
              .getHandler()
              .alertCustom(
                this.translate.instant(
                  'twoFactor.challenge.sms.tooManyRequests',
                ),
              );
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
    } else {
      this.router.navigate([method.route]);
    }
  }
}
