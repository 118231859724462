import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { PasswordResetInitService } from './password-reset-init.service';
import { EMAIL_NOT_FOUND_TYPE } from '../../../shared/constants/error.constants';

@Component({
  selector: 'jhi-password-reset-init',
  templateUrl: './password-reset-init.component.html',
  styleUrl: './password-reset-init.component.scss',
})
export class PasswordResetInitComponent {
  isSubmitting = false;
  error: string;
  errorEmailNotExists: string;
  success: string;
  resetForm = this.fb.group({
    username: ['', [Validators.required]],
  });

  constructor(
    private passwordResetInitService: PasswordResetInitService,
    private fb: FormBuilder,
  ) {
  }

  submitForm() {
    this.error = null;
    this.errorEmailNotExists = null;
    this.isSubmitting = true;

    this.passwordResetInitService
      .save(this.resetForm.get(['username']).value)
      .subscribe(
        () => {
          this.isSubmitting = false;
          this.success = 'OK';
        },
        (response) => {
          this.isSubmitting = false;
          this.success = null;
          if (
            response.status === 400 &&
            response.error.type === EMAIL_NOT_FOUND_TYPE
          ) {
            this.errorEmailNotExists = 'ERROR';
          } else {
            this.error = 'ERROR';
          }
        },
      );
  }
}
