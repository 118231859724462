import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from '../shared/models/select-item';
import { LocalStorageService } from 'ngx-webstorage';
import { DomainConfig } from '../shared/models/domainConfig.model';
import { FsCookieService } from '../shared/services/fs-cookie.service';
import {
  LanguageConfig,
  LanguageService,
} from '../shared/services/language.service';
import { UnsubscriberComponent } from '../shared/components/unsubscriber/unsubscriber.component';
import { LanguageCode } from '../shared/models/langualge.model';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../shared/utils/utils';
import { DomainConfigService } from '../shared/services/domain-config.service';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { environment } from '../../environments/environment';

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent extends UnsubscriberComponent implements OnInit {
  domainConfigData = new DomainConfig();
  uuid = '';
  title = '';
  sub: any;
  availableLanguages: SelectItem[] = LanguageConfig;
  selectedLanguage: any;
  languageCode: LanguageCode;
  version = '';
  environmentName = '';
  languageMenuOpened = false;

  constructor(
    public translate: TranslateService,
    private cookieService: FsCookieService,
    private domainConfigService: DomainConfigService,
    private languageService: LanguageService,
    private snackBarService: SnackBarService,
    private localStorage: LocalStorageService,
  ) {
    super();

    this.languageCode = this.localStorage.retrieve('selectedLanguage')
      ? this.localStorage.retrieve('selectedLanguage')
      : 'en-us';

    if (this.languageCode) {
      this.translate.use(this.languageCode);
    }
  }

  ngOnInit(): void {
    this.version = environment.version;
    this.environmentName = environment.environmentName;

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        if (!Utils.isObjectEmpty(domainData)) {
          this.domainConfigData = domainData;
          this.handleOutageMessage();
        }
      });

    this.languageService.enableSelectLanguage$.subscribe(() => {
      this.languageCode = this.localStorage.retrieve('selectedLanguage')
        ? this.localStorage.retrieve('selectedLanguage')
        : 'en-us';
      this.updateSelectedLanguage();
    });

    this.updateSelectedLanguage();
  }

  handleOutageMessage() {
    // display outageMessage for 5 mins if received from BE
    if (this.domainConfigData.outageMessage) {
      this.snackBarService.showSnackBarWarning(
        this.domainConfigData.outageMessage,
        '',
        5 * 60 * 1000,
      );
    }
  }

  navigateToAppStore(url) {
    window.location.href = url;
  }

  changeLanguage(language: { code: LanguageCode }) {
    this.languageCode = language.code;
    this.languageService.setSelectedLanguage(this.languageCode);

    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('selectedLanguage', language.code);
    }

    this.translate.use(language.code);
    this.translate.setDefaultLang(language.code);
    this.updateSelectedLanguage();
  }

  private updateSelectedLanguage() {
    this.selectedLanguage = this.availableLanguages.find(
      (element) => element.value.code === this.languageCode,
    );
  }
}
