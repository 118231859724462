<div class="container py-4">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.setup.sms.title' | translate }}</h4>
        </div>

        <div class="card-body text-left">
          <form [formGroup]="smsSetupForm" (ngSubmit)="onSubmit()">
            <div class="form-group text-center">
              <label class="form-label">
                {{ 'twoFactor.setup.sms.enterPhoneNumber' | translate }}
              </label>
              <fs-phone-input
                inputClass="form-control"
                [ngInputClass]="{'is-invalid': smsSetupForm.get('phoneNumber').invalid && smsSetupForm.get('phoneNumber').touched}"
                formControlName="phoneNumber"
                [autoFocus]="true"
              />

              <div
                *ngIf="smsSetupForm.get('phoneNumber').invalid && smsSetupForm.get('phoneNumber').touched"
                class="text-danger mt-2"
              >
                <span *ngIf="smsSetupForm.get('phoneNumber').errors?.required && !smsSetupForm.get('phoneNumber').errors?.invalidNumber">
                      {{ 'twoFactor.setup.sms.required' | translate }}
                    </span>
                <span *ngIf="smsSetupForm.get('phoneNumber').errors?.invalidNumber">
                      {{ 'twoFactor.setup.sms.invalidNumber' | translate }}
                    </span>
              </div>
            </div>

            <div class="alert alert-info d-flex align-items-center" role="alert">
              <span class="material-icons mr-2">info</span>
              {{ 'twoFactor.setup.sms.instructions' | translate }}
            </div>

            <div class="d-grid gap-2 mt-4">
              <button
                type="submit"
                class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                [disabled]="!smsSetupForm.valid || isSubmitting"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                {{ 'twoFactor.setup.sms.button' | translate }}
              </button>
            </div>
          </form>

          <div class="mt-4 text-center">
            <a
              [routerLink]="!user.userProfileDTO.twoFactorAuthentication ? '/setup/mfa' : '/dashboard/profile'"
              class="text-decoration-none text-light d-flex align-items-center justify-content-center"
            >
              <i class="material-icons align-middle mr-1" style="font-size: 16px;">arrow_back</i>
              {{ 'twoFactor.setup.back' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
