import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExpressDownloadResultData } from '../../dashboard/project/dashboard-shared/top-menu/express-download/express-download-assets-data.model';

@Injectable({
  // Ensure that only one singleton exists for BehaviorSubjects
  providedIn: 'root'
})
export class ExpressDownloadStoreService {
  private expressDownloadResults = new BehaviorSubject<
    ExpressDownloadResultData[]
  >([]);
  expressDownloadResultsGlobal = this.expressDownloadResults.asObservable();

  setExpressDownloadResults(
    expressDownloadResult: ExpressDownloadResultData,
  ): void {
    const currentState: ExpressDownloadResultData[] =
      this.expressDownloadResults.getValue();
    const updatedArray = [...currentState, expressDownloadResult];
    this.expressDownloadResults.next(updatedArray);
  }

  removeExpressDownloadResultFromPool(id: string): void {
    const currentArray = this.expressDownloadResults.getValue();
    const updatedArray = currentArray.filter(
      (value: ExpressDownloadResultData) => value.id !== id,
    );
    this.expressDownloadResults.next(updatedArray);
  }

  resetExpressDownloadResultFromPool() {
    this.expressDownloadResults.next([]);
  }

  isExpressDownloadResults(): boolean {
    return !!this.expressDownloadResults.getValue().length;
  }
}
