export const environment = {
  production: true,
  log: false,
  logRocket: false,
  resumeSessionOnPageLoad: false,
  version: '8.2.20241212.1',
  environmentName: 'Staging',
  apiUrlList: ['https://testassets.approveedit.com/api/'],
  apiUrl: 'https://gateway.approveedit.com/',
  assetBaseUrl: ['https://testassets.approveedit.com/api/'], // This is for Upload, Download, Email, PDF etc for assets
  projectAssetBaseUrl: 'https://test-project-assets.approveedit.com/', // This is for help videos, announces, branding, project images
  internalBaseUrl: [
    'https://testassets.approveedit.com/api/',
    'https://testassets.approveedit.com/api/',
  ], // super zoom
};
