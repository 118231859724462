<div class="container py-4">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.challenge.selectMethod' | translate }}</h4>
        </div>
        <div class="card-body">
          <div class="list-group list-group-flush">
            <button
              type="button"
              *ngFor="let method of availableMethods"
              class="list-group-item list-group-item-action rounded-0 text-white"
              (click)="selectMethod(method)"
              [disabled]="selectedMethod === method.id"
            >
              <div class="d-flex w-100 justify-content-between align-items-center text-left">
                <div>
                  <h5 class="mb-1">{{ method.nameKey | translate }}</h5>
                  <p class="text-white-50 mb-0">{{ method.descriptionKey | translate }}</p>
                </div>
                <i class="material-icons fa-2x" *ngIf="selectedMethod !== method.id">{{ method.icon }}</i>
                <span
                  *ngIf="selectedMethod === method.id"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
