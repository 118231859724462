import { inject, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { PasswordLoginComponent } from './password-login/password-login.component';
import { PasswordResetInitComponent } from './password-reset/init/password-reset-init.component';
import { PasswordResetFinishComponent } from './password-reset/finish/password-reset-finish.component';
import { MfaLoginOptionsComponent } from './mfa-login-options/mfa-login-options.component';
import { MfaSmsComponent } from './mfa-sms/mfa-sms.component';
import { MfaTOTPComponent } from './mfa-totp/mfa-totp.component';
import { MfaRecoveryCodeComponent } from './mfa-recovery-code/mfa-recovery-code.component';
import { MfaRecoveryCodeResetComponent } from './mfa-recovery-code-reset/mfa-recovery-code-reset.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { UserService } from '../shared/services/user.service';
import { ImpersonateComponent } from './impersonate/impersonate.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: AuthComponent,
        children: [
          { path: '', pathMatch: 'full', component: LoginComponent },
          {
            path: 'login/password',
            component: PasswordLoginComponent,
          },
          {
            path: 'forgot-password',
            component: PasswordResetInitComponent,
          },
          {
            path: 'reset-password/:token',
            component: PasswordResetFinishComponent,
          },
          {
            path: 'mfa-challenge/options',
            component: MfaLoginOptionsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'mfa-challenge/sms',
            component: MfaSmsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'mfa-challenge/totp',
            component: MfaTOTPComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'mfa-challenge/recovery-code',
            component: MfaRecoveryCodeComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'recovery-code-reset',
            component: MfaRecoveryCodeResetComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'onboarding/:key',
            component: OnboardingComponent,
            resolve: {
              authentication: (route: ActivatedRouteSnapshot) => {
                return inject(UserService).authorizeFromOnboardingKey(
                  route.paramMap.get('key')!,
                );
              },
            },
          },
          {
            path: 'impersonate/:token',
            component: ImpersonateComponent,
            resolve: {
              authentication: (route: ActivatedRouteSnapshot) => {
                return inject(UserService).authorizeFromImpersonationToken(
                  route.paramMap.get('token')!,
                );
              },
            },
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AuthRouting {}
