<div class="reset-form">
  <div class="banner fs-text-color-white" *ngIf="success">
    <p>{{ 'login.passwordResetSent' | translate }}</p>
  </div>

  <form [formGroup]="resetForm" (ngSubmit)="submitForm()" autocomplete="off" *ngIf="!success">
    <fieldset [disabled]="isSubmitting">
      <div class="align-items-center d-flex flex-column">
        <input
          fsAutofocus
          formControlName="username"
          type="text"
          name="username"
          placeholder="{{ 'login.username' | translate }}"
          class="login-input"
        />

        <a
          class="login fs-txt-white-light-color"
          routerLink="/"
        >
          {{ 'login.login' | translate }}</a
        >
      </div>
      <div class="reset-btn-container">
        <input
          type="submit"
          class="reset-btn"
          value="{{ 'login.button.reset' | translate }}"
          [disabled]="!resetForm.valid"
        />
      </div>
    </fieldset>
  </form>
</div>

