import { Component, OnInit } from '@angular/core';
import { UserDetails } from '../../shared/models/userdetails.model';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { switchMap } from 'rxjs/operators';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';

interface TwoFactorMethod {
  id: string;
  nameKey: string;
  descriptionKey: string;
  icon: string;
  route: string;
  isActivated: (user: UserDetails) => boolean;
}

@Component({
  selector: 'fs-mfa-setup',
  templateUrl: './mfa-setup.component.html',
  styleUrl: './mfa-setup.component.scss',
})
export class MfaSetupComponent {
  user: UserDetails;
  selectedMethod: string;
  isSubmitting: boolean;
  isSubmittingPriority: string;
  authMethods: TwoFactorMethod[] = [
    {
      id: 'sms',
      nameKey: 'twoFactor.method.sms.name',
      descriptionKey: 'twoFactor.method.sms.explanation',
      icon: 'phone_iphone',
      route: '/setup/mfa/sms',
      isActivated: (user: UserDetails) =>
        user.userProfileDTO.mfaOptionSms && user.userProfileDTO.mfaSmsVerified,
    },
    {
      id: 'totp',
      nameKey: 'twoFactor.method.totp.name',
      descriptionKey: 'twoFactor.method.totp.explanation',
      icon: 'qr_code_2',
      route: '/setup/mfa/totp',
      isActivated: (user: UserDetails) => user.userProfileDTO.mfaOptionTotp,
    },
    {
      id: 'recovery',
      nameKey: 'twoFactor.method.recoveryCode.name',
      descriptionKey: 'twoFactor.method.recoveryCode.explanation',
      icon: 'vpn_key',
      route: '',
      isActivated: () => true,
    },
  ];

  constructor(
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    const user = this.userService.getCurrentUser();
    if (user.userProfileDTO.twoFactorAuthentication) {
      this.userService.handleUserLoginChecks(user);
      return;
    }

    this.user = user;
  }

  async startMethodSetup(method: TwoFactorMethod): Promise<void> {
    if (!method.route) return;

    this.selectedMethod = method.id;

    if (method.id === 'totp') {
      // Initiate the TOTP enrollment flow
      this.userService.mfaEnrollTotpInit().subscribe({
        next: (data) => {
          this.router.navigate([method.route], {
            state: { qrCodeUrl: data.qrCodeUrl, secretState: data.secretState },
          });
        },
        error: (err) => {
          this.apiErrorHandlerService.getHandler().handle(err);
        },
      });
    } else {
      this.router.navigate([method.route]);
    }
  }

  async makePreferred(method: TwoFactorMethod): Promise<void> {
    if (this.isSubmittingPriority) return;

    this.isSubmittingPriority = method.id;

    // Update the preferred choice
    this.userService
      .mfaEnrollConfigurePriority(method.id)
      .pipe(
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
      )
      .subscribe({
        next: (user) => {
          this.isSubmittingPriority = undefined;
          this.user = user;
        },
        error: (err) => {
          this.isSubmittingPriority = undefined;
          this.apiErrorHandlerService.getHandler().handle(err);
        },
      });
  }

  canComplete(): boolean {
    // MFA setup can be complete when two or more methods are added
    return (
      this.authMethods.filter((method) => method.isActivated(this.user))
        .length > 1
    );
  }

  async completeSetup(): Promise<void> {
    if (this.isSubmitting) return;

    this.isSubmitting = true;

    // To complete setup, generate recovery codes
    this.userService.mfaEnrollRecoveryCodeReset().subscribe({
      next: (data) => {
        // And have the user confirm them
        this.router.navigate(['/setup/mfa/finish'], {
          state: { codes: data.recoveryCodes },
        });
      },
      error: (err) => {
        this.isSubmitting = false;
        this.apiErrorHandlerService.getHandler().handle(err);
      },
    });
  }
}
