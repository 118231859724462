import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { UserDetails } from '../../shared/models/userdetails.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fs-mfa-setup-sms',
  templateUrl: './mfa-setup-sms.component.html',
  styleUrl: './mfa-setup-sms.component.scss',
})
export class MfaSetupSmsComponent implements OnInit {
  smsSetupForm: FormGroup;
  isSubmitting = false;
  user: UserDetails;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private translate: TranslateService,
  ) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.smsSetupForm = this.fb.group({
      phoneNumber: ['', [Validators.required]],
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.smsSetupForm.valid) return;

    const phoneNumber = this.smsSetupForm.get('phoneNumber').value;

    this.isSubmitting = true;

    // Send an SMS verification code
    this.userService.mfaEnrollSmsInit(phoneNumber).subscribe({
      next: (data) => {
        this.router.navigate(['/setup/mfa/sms-verify'], {
          state: { phoneNumber: data.phoneNumber },
        });
      },
      error: (err) => {
        this.isSubmitting = false;

        if (err.status === 429) {
          this.apiErrorHandlerService
            .getHandler()
            .alertCustom(
              this.translate.instant(
                'twoFactor.setup.smsVerify.tooManyRequests',
              ),
            );
        } else {
          this.apiErrorHandlerService.getHandler().handle(err);
        }
      },
    });
  }
}
