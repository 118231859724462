import { Component, OnInit } from '@angular/core';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { LoaderService } from '../../shared/services/loader.service';
import { FsCookieService } from '../../shared/services/fs-cookie.service';
import { CookieBannerService } from '../../shared/components/cookie-banner/cookie-banner.service';
import { DomainConfigService } from '../../shared/services/domain-config.service';
import { PreviousRouteService } from '../../shared/services/previous-route.service';
import { AuthServerProvider } from '../../shared/services/auth.jwt.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ExpressDownloadStoreService } from '../../shared/services/express-download-store.service';
import { DownloadManagerService } from '../../shared/layout/download-manager/download-manager.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { takeUntil } from 'rxjs/operators';
import { LogoutConstants } from '../auth.enum';
import { Utils } from '../../shared/utils/utils';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { IpService } from '../../shared/services/ip-service.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fs-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent extends UnsubscriberComponent implements OnInit {
  isDomainSuccess = false;
  authType = '';
  uuid = '';
  title = '';
  isSubmitting = false;
  authForm: UntypedFormGroup;
  sub: any;
  selectedLanguage: any;
  version = '';
  HTTP_STATUS_FORBIDDEN = 403;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    private cookieService: FsCookieService,
    private cookieBannerService: CookieBannerService,
    private domainConfigService: DomainConfigService,
    private previousRouteService: PreviousRouteService,
    private authServerProvider: AuthServerProvider,
    private expressDownloadStoreService: ExpressDownloadStoreService,
    private localStorage: LocalStorageService,
    private downloadManagerService: DownloadManagerService,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private ipService: IpService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private viewProjectService: ViewProjectService,
    private logger: NGXLogger,
  ) {
    super();

    if (!this.cookieService.isCookieAllowed()) {
      this.clearSettings();
    }

    this.router.navigate(['/']);

    this.authForm = this.fb.group({
      username: [
        this.cookieService.isCookieAllowed() &&
        this.localStorage.retrieve('rememberMe') &&
        !!this.localStorage.retrieve('username')
          ? atob(this.localStorage.retrieve('username'))
          : '',
        Validators.required,
      ],
      rememberMe: [
        this.cookieService.isCookieAllowed() &&
          this.localStorage.retrieve('rememberMe'),
      ],
    });

    this.cookieService.acceptCookieStatusGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: boolean) => {
        if (status) {
          this.localStorage.store(
            'rememberMe',
            this.authForm.get('rememberMe').value,
          );
        } else {
          this.authForm.get('rememberMe').setValue(status);
        }
      });

    const preURL = this.previousRouteService.getPreviousUrl();
    if (preURL) {
      this.loaderService.displayLoader(true);

      const refreshToken = this.authServerProvider.getRefreshToken();
      const refreshErrorStatus = this.userService.getRefreshTokenErrorStatus()

      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        refresh_token:
          refreshToken === null ||
          refreshErrorStatus === this.HTTP_STATUS_FORBIDDEN
            ? null
            : refreshToken,
        inactivityLogout:
          this.userService.getInactivityLogout() ||
          Boolean(this.activatedRoute.snapshot.queryParamMap.get('logout')),
      };
      if (data.refresh_token || refreshErrorStatus) {
        this.userService
          .signOut(data)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.userService.stopWatchingRefreshToken();
            this.userService.setRefreshTokenErrorStatus(null);
            this.userService.setInactivityLogout(false);
            this.loaderService.displayLoader(false);
            if (res.status) {
              this.dialogService.closeAll();
              this.router.navigateByUrl('/').then(() => {
                const messages = {
                  [LogoutConstants.LOGOUT_BY_INACTIVITY]:
                    'login.inactivityLoggOut',
                  [LogoutConstants.LOGOUT_BY_REPETITIVE_LOGIN]:
                    'login.repetitiveLoggOut',
                };

                const messageKey =
                  messages[res.message] || 'login.loggOutConfirmation';
                this.alertCustom(this.translate.instant(messageKey));
              });
              this.userService.purgeAuth();
              this.domainConfigService.setIsDomainSuccess(true);
              this.downloadManagerService.setIsDownloadEnabled(false);
            }
          });
      } else {
        this.loaderService.displayLoader(false);
      }
    }
  }

  ngOnInit(): void {
    this.expressDownloadStoreService.resetExpressDownloadResultFromPool();
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.authType = 'login';
      this.title = 'Sign in';
    });

    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        // If the app has already initialized, set the isDomainReady flag
        if (!Utils.isObjectEmpty(domainData) && domainData.version) {
          this.isDomainSuccess = true;
        }
      });

    this.domainConfigService.isDomainSuccess
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isDomainSuccess = status;
        if (this.isDomainSuccess === false) {
          this.authForm.controls['username'].disable();
        }
      });

    // If we're reloading the app, we may in the process of signing in
    const reloadAuthData = JSON.parse(
      this.localStorage.retrieve('reloadAuthData'),
    );
    if (reloadAuthData && Object.keys(reloadAuthData).length !== 0) {
      this.loaderService.displayLoader(true);

      this.snackBarService.showSnackBar(
        this.translate.instant('login.successMsg'),
      );

      // Set the auth to the stored data
      this.userService.setAuth(reloadAuthData);

      // And activate their session
      this.userService.activateSession().subscribe({
        next: () => {
          this.localStorage.store('reloadAuthData', JSON.stringify({}));

          // Update remember-me
          if (this.canSaveAuthData()) {
            this.localStorage.store(
              'username',
              btoa(this.authForm.getRawValue().username),
            );
          }
        },
        error: (error) => {
          this.apiErrorHandlerService.getHandler().handle(error);
        },
      });
    }
  }

  onRememberChange() {
    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('rememberMe', this.authForm.value.rememberMe);
      //TODO: Try to store password in local storage in more secure way later
      if (!this.localStorage.retrieve('rememberMe')) {
        this.localStorage.store('username', '');
        this.authForm.controls['username'].enable();
      }
    } else {
      this.cookieBannerService.open();
    }
  }

  clearSettings() {
    this.localStorage.clear('username');
    //TODO: Try to store password in local storage in more secure way later
    this.localStorage.clear('rememberMe');
  }

  submitForm() {
    if (this.isDomainSuccess) {
      this.router.navigate(['login/password'], {
        state: { username: this.authForm.getRawValue().username.trim() },
      });
    } else {
      this.alertCustom(this.translate.instant('error.networkError'));
    }
  }

  canSaveAuthData() {
    return (
      this.cookieService.isCookieAllowed() &&
      this.localStorage.retrieve('rememberMe')
    );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
