<div class="container py-4">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div *ngIf="!authenticated">{{ 'onboarding.invalid' | translate }} <a
        class="login fs-txt-white-light-color" routerLink="/"
      >{{ 'onboarding.login' | translate }}</a></div>
    </div>
  </div>
</div>

