<div class="container py-4">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <h3 class="mb-4 text-white text-center">{{ 'twoFactor.setup.title' | translate }}</h3>

      <div
        *ngFor="let method of authMethods"
        class="mb-3 card bg-dark text-white shadow-sm"
      >
        <div class="card-body d-flex align-items-center text-left">
          <div class="auth-method-icon mr-4">
                <span
                  class="material-icons fa-3x"
                  [ngClass]="{
                    'text-primary': method.isActivated(user),
                    'text-muted': !method.isActivated(user)
                  }"
                >
                  {{ method.icon }}
                </span>
          </div>
          <div class="flex-grow-1">
            <h5 class="card-title mb-2">
              {{ method.nameKey | translate }}
            </h5>
            <p class="card-text text-white-50 mb-2">
              {{ method.descriptionKey | translate }}
            </p>
            <div class="d-flex align-items-center">
                  <span
                    *ngIf="method.isActivated(user)"
                    class="badge bg-success mr-2 px-2 d-flex align-items-center"
                  ><span
                    class="material-icons mr-1"
                  >check_circle</span> {{ 'twoFactor.setup.activated' | translate }}</span>
              <span
                *ngIf="user.userProfileDTO.mfaPriorityChoice === method.id"
                class="badge bg-primary d-flex px-2 align-items-center"
              ><span class="material-icons mr-1">star</span> {{ 'twoFactor.setup.preferred' | translate }}</span>
            </div>
          </div>
          <div class="auth-method-actions ml-4 text-nowrap">
            <button
              *ngIf="!method.isActivated(user) && method.id !== 'recovery'"
              class="btn btn-outline-primary d-flex justify-content-center align-items-center"
              (click)="startMethodSetup(method)"
              [disabled]="selectedMethod === method.id"
            >
              <span class="material-icons mr-2">add_circle</span>
              {{ 'twoFactor.setup.button.setup' | translate }}
            </button>
            <button
              *ngIf="method.isActivated(user) && method.id !== 'recovery' && user.userProfileDTO.mfaPriorityChoice !== method.id"
              class="btn btn-outline-light d-flex justify-content-center align-items-center"
              (click)="makePreferred(method)"
              [disabled]="isSubmittingPriority"
            >
              <span
                *ngIf="isSubmittingPriority === method.id"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
              ></span>
              {{ 'twoFactor.setup.button.makePreferred' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="text-center mt-4">
        <button
          class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
          [disabled]="!canComplete() || isSubmitting"
          (click)="completeSetup()"
        >
          <span
            *ngIf="isSubmitting"
            class="spinner-border spinner-border-sm mr-2"
            role="status"
          ></span>
          {{ 'twoFactor.setup.complete' | translate }}
        </button>

        <a
          routerLink="/dashboard"
          class="mt-3 text-light text-decoration-none d-flex align-items-center justify-content-center"
        >
          {{ 'twoFactor.setup.skip' | translate }}
          <i class="material-icons align-middle ml-1" style="font-size: 16px;">arrow_forward</i>
        </a>
      </div>
    </div>
  </div>
</div>
