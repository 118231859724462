import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { LoaderService } from '../../shared/services/loader.service';
import { IpService } from '../../shared/services/ip-service.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { FsCookieService } from '../../shared/services/fs-cookie.service';
import { CookieBannerService } from '../../shared/components/cookie-banner/cookie-banner.service';
import { DomainConfigService } from '../../shared/services/domain-config.service';
import { PreviousRouteService } from '../../shared/services/previous-route.service';
import { AuthServerProvider } from '../../shared/services/auth.jwt.service';
import { NGXLogger } from 'ngx-logger';
import { ExpressDownloadStoreService } from '../../shared/services/express-download-store.service';
import { LocalStorageService } from 'ngx-webstorage';
import { ViewProjectService } from '../../shared/services/view-project.service';
import { DownloadManagerService } from '../../shared/layout/download-manager/download-manager.service';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorCodeConfig } from '../../appSettings/error-codes-config';
import { UnsubscriberComponent } from '../../shared/components/unsubscriber/unsubscriber.component';

@Component({
  selector: 'fs-password-login',
  templateUrl: './password-login.component.html',
  styleUrl: './password-login.component.scss',
})
export class PasswordLoginComponent
  extends UnsubscriberComponent
  implements OnInit
{
  authType = '';
  uuid = '';
  title = '';
  isSubmitting = false;
  authForm: UntypedFormGroup;
  sub: any;
  selectedLanguage: any;
  version = '';
  isAuthenticated = false;
  authResponse: any;
  USER_IS_NOT_ACTIVE = 'UserIsNotActive';

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    private ipService: IpService,
    private apiErrorHandlerService: ApiErrorHandlerService,
    private cookieService: FsCookieService,
    private cookieBannerService: CookieBannerService,
    private domainConfigService: DomainConfigService,
    private previousRouteService: PreviousRouteService,
    private authServerProvider: AuthServerProvider,
    private logger: NGXLogger,
    private expressDownloadStoreService: ExpressDownloadStoreService,
    private localStorage: LocalStorageService,
    private viewProjectService: ViewProjectService,
    private downloadManagerService: DownloadManagerService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
  ) {
    super();

    if (!history.state.username) {
      this.router.navigateByUrl('/');
      return;
    }

    //TODO: Try to store password in local storage in more secure way later
    this.authForm = this.fb.group({
      username: [history.state.username, Validators.required],
      password: ['', Validators.required],
      rememberMe: [
        this.cookieService.isCookieAllowed() &&
          this.localStorage.retrieve('rememberMe'),
      ],
    });

    this.cookieService.acceptCookieStatusGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: boolean) => {
        if (status) {
          this.localStorage.store(
            'rememberMe',
            this.authForm.get('rememberMe').value,
          );
        } else {
          this.authForm.get('rememberMe').setValue(status);
        }
      });
  }

  ngOnInit(): void {
    this.expressDownloadStoreService.resetExpressDownloadResultFromPool();
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.authType = 'login';
      this.title = 'Sign in';
    });
  }

  onRememberChange() {
    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('rememberMe', this.authForm.value.rememberMe);
      //TODO: Try to store password in local storage in more secure way later
      if (!this.localStorage.retrieve('rememberMe')) {
        this.localStorage.store('username', '');
        this.authForm.controls['username'].enable();
      }
    } else {
      this.cookieBannerService.open();
    }
  }

  submitForm() {
    this.userService.resetData();
    this.userService.resetUserAuthority();

    const obj = {
      device: {
        ipAddress: '',
        source: 'web',
        version: environment.version,
      },
      username: this.authForm.getRawValue().username.trim(),
      password: this.authForm.value.password.trim(),
      // Provide the base app URL to the authentication API
      sendingUrl: `${window.location.origin}/#/`,
      rememberMe: this.authForm.value.rememberMe,
    };
    this.authenticate(obj);
  }

  authenticate(credentials) {
    this.isSubmitting = true;
    this.loaderService.displayLoader(true);

    this.userService
      .attemptAuth('api/authenticate', credentials)
      .pipe(
        map((data) => {
          if (!data.id_token) {
            this.snackBarService.showSnackBar(data.message);
            this.uuid = data.uuid;
            this.isSubmitting = false;
            this.loaderService.displayLoader(false);
            return null;
          } else if (this.reloadRequire(data)) {
            return null;
          }

          return data;
        }),
        // If the login wasn't intercepted...
        filter((data) => !!data),
        // Show UI success
        tap((data) => {
          this.snackBarService.showSnackBar(
            this.translate.instant('login.successMsg'),
          );

          // Update remember-me
          if (this.canSaveAuthData()) {
            this.localStorage.store(
              'username',
              btoa(this.authForm.getRawValue().username),
            );
          }
        }),
        // And activate their session
        switchMap(() => this.userService.activateSession()),
      )
      .subscribe({
        next: () => {
          this.loaderService.displayLoader(false);
        },
        error: (error) => {
          this.isSubmitting = false;
          this.loaderService.displayLoader(false);
          if (error.error.detail === this.USER_IS_NOT_ACTIVE) {
            this.alertCustom(this.translate.instant('login.userIsNotActive'));
          } else if (error.status === ErrorCodeConfig.UNAUTHORIZED) {
            this.alertCustom(
              this.translate.instant('login.invalidCredentials'),
            );
          } else {
            this.apiErrorHandlerService.getHandler().handle(error);
          }
        },
      });
  }

  reloadRequire(res?) {
    const version = this.localStorage.retrieve('version');
    if (version === '') {
      this.localStorage.store('version', res.version);
      return false;
    } else if (version !== res.version) {
      this.authResponse = res;
      this.loaderService.displayLoader(false);

      this.localStorage.store(
        'reloadAuthData',
        res ? JSON.stringify(res) : JSON.stringify({}),
      );
      this.reload();

      return true;
    } else {
      return false;
    }
  }

  reload() {
    this.localStorage.store('version', this.authResponse.version);
    location.reload();
  }

  canSaveAuthData() {
    return (
      this.cookieService.isCookieAllowed() &&
      this.localStorage.retrieve('rememberMe')
    );
  }

  alertCustom(displayText: string) {
    this.dialogService.openInformationDialog({
      title: this.translate.instant('view-project.confirm22'),
      message: displayText,
    });
  }
}
