<div class="container py-4">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'twoFactor.challenge.recoveryCode.title' | translate }}</h4>
        </div>

        <div class="card-body">
          <h6 class="mb-4">
            {{ 'twoFactor.challenge.recoveryCode.description' | translate }}
          </h6>

          <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="recoveryCode" class="sr-only">
                {{ 'twoFactor.challenge.recoveryCode.code' | translate }}
              </label>
              <input
                fsAutofocus
                type="text"
                id="recoveryCode"
                class="form-control form-control-lg text-center"
                formControlName="recoveryCode"
                [placeholder]="'twoFactor.challenge.recoveryCode.code' | translate"
                [class.is-invalid]="showError"
                (input)="formatCode($event)"
                autocomplete="off"
              >
            </div>

            <!-- Error Message -->
            <div
              *ngIf="showError"
              class="alert alert-danger d-flex align-items-center mt-3 text-left"
              role="alert"
            >
              <i class="material-icons mr-2">error_outline</i>
              <div *ngIf="showError === 'error'">{{ 'twoFactor.challenge.recoveryCode.error' | translate }}</div>
              <div *ngIf="showError === 'tooManyRequests'">{{ 'twoFactor.challenge.tooManyRequests' | translate }}</div>
            </div>

            <div class="d-grid gap-2 mt-4">
              <button
                type="submit"
                class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                [disabled]="!recoveryForm.valid || isSubmitting"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                {{ 'twoFactor.challenge.recoveryCode.button' | translate }}
              </button>
            </div>
          </form>

          <div class="mt-4 text-center">
            <div class="mb-3">
              <a
                routerLink="/mfa-challenge/options"
                class="text-decoration-none text-light d-flex align-items-center justify-content-center"
              >
                <i class="material-icons align-middle mr-1" style="font-size: 16px;">arrow_back</i>
                {{ 'twoFactor.challenge.back' | translate }}
              </a>
            </div>
            <p class="text-white-50">
              {{ 'twoFactor.challenge.recoveryCode.help' | translate }}
              <a href="mailto:info@filmsolutions.com?Subject=Contact%20Support" class="text-primary">
                {{ 'twoFactor.challenge.recoveryCode.contact' | translate }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
