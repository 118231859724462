import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import intlTelInput from 'intl-tel-input';

const validationError = {
  IS_POSSIBLE: 0,
  INVALID_COUNTRY_CODE: 1,
  TOO_SHORT: 2,
  TOO_LONG: 3,
  IS_POSSIBLE_LOCAL_ONLY: 4,
  INVALID_LENGTH: 5,
};

@Component({
  selector: 'fs-phone-input',
  templateUrl: './fs-phone-input.component.html',
  styleUrl: './fs-phone-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FsPhoneInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FsPhoneInputComponent),
      multi: true,
    },
  ],
})
export class FsPhoneInputComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input() inputAutocomplete = '';
  @Input() inputClass = '';
  @Input() ngInputClass: { [key: string]: boolean } = {};
  @Input() autoFocus = false;

  private iti: any;
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};
  public phoneControl = new FormControl('');
  private inputChanged = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const input = this.elementRef.nativeElement.querySelector('#phone');
    this.iti = intlTelInput(input, {
      loadUtilsOnInit: () =>
        new Promise((resolve, reject) => {
          import('intl-tel-input/build/js/utils.js').then(resolve, reject);
        }),
      separateDialCode: false,
      formatOnDisplay: true,
      initialCountry: 'us',
    });

    input.addEventListener('countrychange', () => {
      this.validateNumber();
    });

    this.phoneControl.valueChanges.subscribe(() => {
      this.inputChanged = true;
      this.validateNumber();
    });
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.elementRef.nativeElement.querySelector('#phone').focus();
    }
  }

  ngOnDestroy() {
    if (this.iti) {
      this.iti.destroy();
    }
  }

  validateNumber() {
    if (this.phoneControl.value) {
      if (!this.iti.isValidNumber()) {
        const errorCode = this.iti.getValidationError();
        const error = this.getValidationError(errorCode);
        this.phoneControl.setErrors(error);
        this.onChange('');
      } else {
        this.phoneControl.setErrors(null);
        this.onChange(this.iti.getNumber());
      }
    } else {
      this.phoneControl.setErrors(null);
      this.onChange('');
    }
  }

  inputBlur() {
    // We don't want to fire onTouched if the user is attempting to use the country dropdown
    if (!this.inputChanged) return;
    this.onTouched();
  }

  private getValidationError(errorCode: number): ValidationErrors {
    switch (errorCode) {
      case validationError.INVALID_COUNTRY_CODE:
        return { invalidNumber: true };
      case validationError.TOO_SHORT:
        return { invalidNumber: true };
      case validationError.TOO_LONG:
        return { invalidNumber: true };
      default:
        return { invalidNumber: true };
    }
  }

  // ControlValueAccessor implementation
  writeValue(value: string): void {
    if (value && this.iti) {
      this.iti.setNumber(value);
      this.phoneControl.setValue(this.iti.getNumber(), { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    const input = this.elementRef.nativeElement.querySelector('#phone');
    input.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.phoneControl.errors;
  }
}
