import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'fs-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrl: './impersonate.component.scss',
})
export class ImpersonateComponent {
  authenticated: boolean;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
  ) {
    this.authenticated = this.route.snapshot.data.authentication;

    if (this.authenticated) {
      // Should we show the profile/mfa setup steps for impersonated users?
      //this.userService.handleUserLoginChecks(this.userService.getCurrentUser());
      this.router.navigateByUrl('/dashboard');
      return;
    }
  }
}
