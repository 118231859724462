<div class="container py-4">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="card bg-dark text-white shadow-sm">
        <div class="card-header bg-secondary text-white">
          <h4 class="mb-0">{{ 'onboarding.profile.title' | translate }}</h4>
        </div>

        <div class="card-body">
          <div class="text-center mb-4">
            <h6 class="mb-0">
              {{ 'onboarding.profile.description' | translate }}
            </h6>
          </div>

          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
              <div class="form-group col-md-7">
                <label
                  class="form-control-label" for="fullName"
                >{{ 'onboarding.profile.fullName.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="fullName" name="fullName"
                  placeholder="{{ 'onboarding.profile.fullName.label' | translate }}"
                  formControlName="fullName"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('fullName').invalid && (profileForm.get('fullName').dirty || profileForm.get('fullName').touched)"
                >{{ 'onboarding.profile.fullName.required' | translate }}</small>
              </div>

              <div class="form-group col-md-5">
                <label
                  class="form-control-label" for="title"
                >{{ 'onboarding.profile.contactTitle.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="title" name="title"
                  placeholder="{{ 'onboarding.profile.contactTitle.label' | translate }}"
                  formControlName="title"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('title').invalid && (profileForm.get('title').dirty || profileForm.get('title').touched)"
                >{{ 'onboarding.profile.title.required' | translate }}</small>
              </div>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="email"
              >{{ 'onboarding.profile.email.label' | translate }}</label>
              <input
                type="text" class="form-control text-center" id="email" name="email"
                placeholder="{{ 'onboarding.profile.email.label' | translate }}"
                formControlName="email"
              >
              <small
                class="form-text text-danger"
                *ngIf="profileForm.get('email').invalid && (profileForm.get('email').dirty || profileForm.get('email').touched)"
              >{{ 'onboarding.profile.email.required' | translate }}</small>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="phoneMobile"
              >{{ 'onboarding.profile.phoneMobile.label' | translate }}</label>
              <input
                type="text" class="form-control text-center" id="phoneMobile" name="phoneMobile"
                placeholder="{{ 'onboarding.profile.phoneMobile.label' | translate }}"
                formControlName="phoneMobile"
              >
              <small
                class="form-text text-danger"
                *ngIf="profileForm.get('phoneMobile').invalid && (profileForm.get('phoneMobile').dirty || profileForm.get('phoneMobile').touched)"
              >{{ 'onboarding.profile.phoneMobile.required' | translate }}</small>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label
                  class="form-control-label" for="phoneOffice"
                >{{ 'onboarding.profile.phoneOffice.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="phoneOffice" name="phoneOffice"
                  placeholder="{{ 'onboarding.profile.phoneOffice.label' | translate }}"
                  formControlName="phoneOffice"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('phoneOffice').invalid && (profileForm.get('phoneOffice').dirty || profileForm.get('phoneOffice').touched)"
                >{{ 'onboarding.profile.phoneOffice.required' | translate }}</small>
              </div>

              <div class="form-group col-md-6">
                <label
                  class="form-control-label" for="phoneAlternate"
                >{{ 'onboarding.profile.phoneAlternate.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="phoneAlternate" name="phoneAlternate"
                  placeholder="{{ 'onboarding.profile.phoneAlternate.label' | translate }}"
                  formControlName="phoneAlternate"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('phoneAlternate').invalid && (profileForm.get('phoneAlternate').dirty || profileForm.get('phoneAlternate').touched)"
                >{{ 'onboarding.profile.phoneAlternate.required' | translate }}</small>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label
                  class="form-control-label" for="email2"
                >{{ 'onboarding.profile.email2.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="email2" name="email2"
                  placeholder="{{ 'onboarding.profile.email2.label' | translate }}"
                  formControlName="email2"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('email2').invalid && (profileForm.get('email2').dirty || profileForm.get('email2').touched)"
                >{{ 'onboarding.profile.email2.required' | translate }}</small>
              </div>

              <div class="form-group col-md-6">
                <label
                  class="form-control-label" for="phoneFax"
                >{{ 'onboarding.profile.phoneFax.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="phoneFax" name="phoneFax"
                  placeholder="{{ 'onboarding.profile.phoneFax.label' | translate }}"
                  formControlName="phoneFax"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('phoneFax').invalid && (profileForm.get('phoneFax').dirty || profileForm.get('phoneFax').touched)"
                >{{ 'onboarding.profile.phoneFax.required' | translate }}</small>
              </div>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="streetAddress"
              >{{ 'onboarding.profile.streetAddress.label' | translate }}</label>
              <input
                type="text" class="form-control text-center" id="streetAddress" name="streetAddress"
                placeholder="{{ 'onboarding.profile.streetAddress.label' | translate }}"
                formControlName="streetAddress"
              >
              <small
                class="form-text text-danger"
                *ngIf="profileForm.get('streetAddress').invalid && (profileForm.get('streetAddress').dirty || profileForm.get('streetAddress').touched)"
              >{{ 'onboarding.profile.streetAddress.required' | translate }}</small>
            </div>

            <div class="form-row">
              <div class="form-group col-md-4">
                <label
                  class="form-control-label" for="city"
                >{{ 'onboarding.profile.city.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="city" name="city"
                  placeholder="{{ 'onboarding.profile.city.label' | translate }}"
                  formControlName="city"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('city').invalid && (profileForm.get('city').dirty || profileForm.get('city').touched)"
                >{{ 'onboarding.profile.city.required' | translate }}</small>
              </div>

              <div class="form-group col-md-4">
                <label
                  class="form-control-label" for="state"
                >{{ 'onboarding.profile.state.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="state" name="state"
                  placeholder="{{ 'onboarding.profile.state.label' | translate }}"
                  formControlName="state"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('state').invalid && (profileForm.get('state').dirty || profileForm.get('state').touched)"
                >{{ 'onboarding.profile.state.required' | translate }}</small>
              </div>

              <div class="form-group col-md-4">
                <label
                  class="form-control-label" for="zipcode"
                >{{ 'onboarding.profile.zipcode.label' | translate }}</label>
                <input
                  type="text" class="form-control text-center" id="zipcode" name="zipcode"
                  placeholder="{{ 'onboarding.profile.zipcode.label' | translate }}"
                  formControlName="zipcode"
                >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('zipcode').invalid && (profileForm.get('zipcode').dirty || profileForm.get('zipcode').touched)"
                >{{ 'onboarding.profile.zipcode.required' | translate }}</small>
              </div>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="country"
              >{{ 'onboarding.profile.country.label' | translate }}</label>
              <input
                type="text" class="form-control text-center" id="country" name="country"
                placeholder="{{ 'onboarding.profile.country.label' | translate }}"
                formControlName="country"
              >
              <small
                class="form-text text-danger"
                *ngIf="profileForm.get('country').invalid && (profileForm.get('country').dirty || profileForm.get('country').touched)"
              >{{ 'onboarding.profile.country.required' | translate }}</small>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="website"
              >{{ 'onboarding.profile.website.label' | translate }}</label>
              <input
                type="text" class="form-control text-center" id="website" name="website"
                placeholder="{{ 'onboarding.profile.website.label' | translate }}"
                formControlName="website"
              >
              <small
                class="form-text text-danger"
                *ngIf="profileForm.get('website').invalid && (profileForm.get('website').dirty || profileForm.get('website').touched)"
              >{{ 'onboarding.profile.website.required' | translate }}</small>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="password"
              >{{ 'resetPassword.newpassword.label' | translate }}</label>
              <input
                type="password" class="form-control text-center" id="password" name="password"
                placeholder="{{ 'login.password' | translate }}"
                formControlName="newPassword"
              >
              <div
                *ngIf="profileForm.get('newPassword').invalid && (profileForm.get('newPassword').dirty || profileForm.get('newPassword').touched)"
              >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('newPassword').errors.required"
                >{{ 'resetPassword.password.required' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('newPassword').errors.minlength"
                >{{ 'resetPassword.password.minlength' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('newPassword').errors.maxlength"
                >{{ 'resetPassword.password.maxlength' | translate }}</small>
              </div>
              <jhi-password-strength-bar
                [passwordToCheck]="profileForm.get('newPassword').value"
              ></jhi-password-strength-bar>
            </div>

            <div class="form-group">
              <label
                class="form-control-label" for="confirmPassword"
              >{{ 'resetPassword.confirmpassword.label' | translate }}</label>
              <input
                type="password" class="form-control text-center" id="confirmPassword" name="confirmPassword"
                placeholder="{{ 'login.password' | translate }}"
                formControlName="confirmPassword"
              >
              <div
                *ngIf="profileForm.get('confirmPassword').invalid && (profileForm.get('confirmPassword').dirty || profileForm.get('confirmPassword').touched)"
              >
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('confirmPassword').errors.required"
                >{{ 'resetPassword.password.required' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('confirmPassword').errors.minlength"
                >{{ 'resetPassword.password.minlength' | translate }}</small>
                <small
                  class="form-text text-danger"
                  *ngIf="profileForm.get('confirmPassword').errors.maxlength"
                >{{ 'resetPassword.password.maxlength' | translate }}</small>
              </div>
            </div>

            <div class="alert alert-danger" *ngIf="error">
              {{ 'resetPassword.messages.error' | translate }}
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch">
              {{ 'resetPassword.messages.doesnotmatch' | translate }}
            </div>

            <div class="d-grid gap-2 mt-4">
              <button
                type="submit"
                class="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                [disabled]="!profileForm.valid || isSubmitting"
              >
                <span
                  *ngIf="isSubmitting"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></span>
                {{ 'onboarding.profile.button' | translate }}
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
