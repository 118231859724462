<div class="fs-popup-title">
  <div class="modal-title text-uppercase">{{ currentUser | fullName }}</div>
</div>

<div class="fs-popup-content fs-user-avatar-menu-content">
  <div
      class="fs-txt-white-dark-color fs-txt-white-grey-color-hover fs-cursor-pointer fs-sm-txt"
      routerLink="/dashboard/profile"
    >
    <i class="fa fa-fw fa-user"></i> {{ 'header.settings' | translate }}
  </div>

  <div
    class="mt-3 fs-txt-white-dark-color fs-txt-white-grey-color-hover fs-cursor-pointer fs-sm-txt"
    (click)="signOut()"
  >
    <i class="fa fa-fw fa-sign-out"></i> {{ 'header.signout' | translate }}
  </div>
</div>
