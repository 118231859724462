import { Component, OnInit } from '@angular/core';
import { UnsubscriberComponent } from '../shared/components/unsubscriber/unsubscriber.component';
import { DomainConfig } from '../shared/models/domainConfig.model';
import { TranslateService } from '@ngx-translate/core';
import { FsCookieService } from '../shared/services/fs-cookie.service';
import { DomainConfigService } from '../shared/services/domain-config.service';
import {
  LanguageConfig,
  LanguageService,
} from '../shared/services/language.service';
import { LocalStorageService } from 'ngx-webstorage';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '../shared/utils/utils';
import { SelectItem } from '../shared/models/select-item';
import { LanguageCode } from '../shared/models/langualge.model';
import { environment } from '../../environments/environment';

@Component({
  selector: 'fs-setup',
  templateUrl: './setup.component.html',
  styleUrl: './setup.component.scss',
})
export class SetupComponent extends UnsubscriberComponent implements OnInit {
  protected domainConfigData = new DomainConfig();
  availableLanguages: SelectItem[] = LanguageConfig;
  selectedLanguage: any;
  languageCode: LanguageCode;
  version = '';
  environmentName = '';
  languageMenuOpened = false;

  constructor(
    public translate: TranslateService,
    private cookieService: FsCookieService,
    private domainConfigService: DomainConfigService,
    private languageService: LanguageService,
    private localStorage: LocalStorageService,
  ) {
    super();

    // Footer
    this.version = environment.version;
    this.environmentName = environment.environmentName;

    this.languageCode = this.localStorage.retrieve('selectedLanguage')
      ? this.localStorage.retrieve('selectedLanguage')
      : 'en-us';

    if (this.languageCode) {
      this.translate.use(this.languageCode);
    }

    this.languageService.enableSelectLanguage$.subscribe(() => {
      this.languageCode = this.localStorage.retrieve('selectedLanguage')
        ? this.localStorage.retrieve('selectedLanguage')
        : 'en-us';
      this.updateSelectedLanguage();
    });

    this.updateSelectedLanguage();
  }

  ngOnInit(): void {
    this.domainConfigService.domainConfigDataGlobal
      .pipe(takeUntil(this.destroy$))
      .subscribe((domainData) => {
        if (!Utils.isObjectEmpty(domainData)) {
          this.domainConfigData = domainData;
        }
      });
  }

  changeLanguage(language: { code: LanguageCode }) {
    this.languageCode = language.code;
    this.languageService.setSelectedLanguage(this.languageCode);

    if (this.cookieService.isCookieAllowed()) {
      this.localStorage.store('selectedLanguage', language.code);
    }

    this.translate.use(language.code);
    this.translate.setDefaultLang(language.code);
    this.updateSelectedLanguage();
  }

  private updateSelectedLanguage() {
    console.log(this.availableLanguages, this.languageCode);
    this.selectedLanguage = this.availableLanguages.find(
      (element) => element.value.code === this.languageCode,
    );
  }
}
