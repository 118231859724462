import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';
import { ApiErrorHandlerService } from '../../shared/services/api-error-handler.service';
import { formatPhoneNumber } from '../../shared/utils/phone-number';
import { UserDetails } from '../../shared/models/userdetails.model';

@Component({
  selector: 'fs-mfa-setup-sms-verify',
  templateUrl: './mfa-setup-sms-verify.component.html',
  styleUrl: './mfa-setup-sms-verify.component.scss',
})
export class MfaSetupSmsVerifyComponent implements OnInit, OnDestroy {
  verificationForm: FormGroup;
  isSubmitting = false;
  isSubmittingResend = false;
  showError: 'error' | 'tooManyRequests' | false = false;
  resendCountdown = 0;
  countdownSubscription: Subscription;
  phone: string;
  formattedPhone: string;
  user: UserDetails;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private apiErrorHandlerService: ApiErrorHandlerService,
  ) {
    this.user = this.userService.getCurrentUser();

    this.phone = history.state.phoneNumber;

    if (!this.phone) {
      // Redirect if no phone number is present
      this.router.navigate(['/setup/mfa/phone']);
    }

    this.formattedPhone = formatPhoneNumber(this.phone);
  }

  ngOnInit(): void {
    this.verificationForm = this.fb.group({
      code: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    });

    // Start with initial countdown
    this.startResendCountdown();

    // Auto-submit when 6 digits are entered
    this.verificationForm.get('code').valueChanges.subscribe((value) => {
      if (value.length === 6) {
        this.onSubmit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  formatInput(event: any): void {
    const input = event.target;
    let value = input.value.replace(/\D/g, '').substring(0, 6);
    input.value = value;
    this.verificationForm.patchValue({ code: value }, { emitEvent: true });
  }

  startResendCountdown(): void {
    this.resendCountdown = 30;

    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }

    this.countdownSubscription = interval(1000)
      .pipe(
        map(() => {
          if (this.resendCountdown > 0) {
            this.resendCountdown--;
          }
          return this.resendCountdown;
        }),
      )
      .subscribe();
  }

  async resendCode(): Promise<void> {
    if (
      this.resendCountdown > 0 ||
      this.isSubmitting ||
      this.isSubmittingResend
    ) {
      return;
    }

    this.isSubmittingResend = true;

    this.userService.mfaEnrollSmsInit(this.phone).subscribe({
      next: () => {
        this.verificationForm.reset();
        this.showError = false;
        this.isSubmittingResend = false;
        this.startResendCountdown();
      },
      error: (err) => {
        this.isSubmittingResend = false;
        this.apiErrorHandlerService.getHandler().handle(err);
      },
    });
  }

  async onSubmit(): Promise<void> {
    if (!this.verificationForm.valid || this.isSubmitting) return;

    this.isSubmitting = true;
    this.showError = false;

    const code = this.verificationForm.get('code').value;

    // Finish SMS enrollment
    this.userService
      .mfaEnrollSmsFinish(this.phone, code)
      .pipe(
        // Update the user's profile/contact data
        // This crosses a service boundary, so making this atomic is difficult
        switchMap(() =>
          this.userService.updateContactProfilePhone(formatPhoneNumber(this.phone)),
        ),
        // Fetch the updated user profile
        switchMap(() => this.userService.userDetail('api/users/user')),
      )
      .subscribe({
        next: () => {
          if (!this.user.userProfileDTO.twoFactorAuthentication) {
            this.router.navigate(['/setup/mfa']);
          } else {
            this.router.navigate(['/dashboard/profile']);
          }
        },
        error: (err) => {
          this.isSubmitting = false;
          if (err.status === 403 || err.status === 429) {
            this.showError = err.status != 429 ? 'error' : 'tooManyRequests';
            this.verificationForm.patchValue({ code: '' });
          } else {
            this.apiErrorHandlerService.getHandler().handle(err);
          }
        },
      });
  }
}
