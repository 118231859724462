import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'jhi-password-reset-finish',
  templateUrl: './password-reset-finish.component.html',
})
export class PasswordResetFinishComponent implements OnInit, AfterViewInit {
  doNotMatch: string;
  error: string;
  success: string;
  isSubmitting = false;
  key: string;

  passwordForm = this.fb.group({
    newPassword: [
      '',
      [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    ],
    confirmPassword: [
      '',
      [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    ],
  });

  constructor(
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.key = params['token'];
    });
  }

  ngAfterViewInit() {
    const passwordElement =
      this.elementRef.nativeElement.querySelector('#password');
    if (passwordElement) {
      this.renderer.selectRootElement(passwordElement).focus();
    }
  }

  finishReset() {
    this.doNotMatch = null;
    this.error = null;
    const password = this.passwordForm.get(['newPassword']).value;
    const confirmPassword = this.passwordForm.get(['confirmPassword']).value;
    if (password !== confirmPassword) {
      this.doNotMatch = 'ERROR';
    } else {
      this.isSubmitting = true;
      this.userService
        .resetPasswordFinish(this.key, password)
        .subscribe({
          next: () => {
            this.isSubmitting = false;
            this.success = 'OK';
          },
          error: () => {
            this.isSubmitting = false;
            this.success = null;
            this.error = 'ERROR';
          },
        });
    }
  }

  login() {
    this.router.navigateByUrl('/');
  }
}
